import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { ReactComponent } from 'support-js/react/component'
import { TopBannerComponent } from 'components/top-banner'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { observable } from 'mobx'
import { ChannelInstance } from 'entrypoints/channel'

@inject('store')
@observer
export class HeaderComponent extends ReactComponent<any, any> {
    menus = [
        {
            name:     '굿핀소개',
            pk:       'introduce',
            children: [
                {
                    name: '굿핀소개',
                    url:  '/introduce'
                },
                {
                    name: '특허개요',
                    url:  '/patent'
                }
            ]
        },
        {
            name:     '이용안내',
            children: [
                {
                    name: '구매하기',
                    url:  '/howto/buy'
                },
                {
                    name: '상품권확인',
                    url:  '/howto/confirm'
                },
                {
                    name: '선물하기',
                    url:  '/howto/send'
                },
                {
                    name: '구매내역 확인',
                    url:  '/howto/list'
                },
                {
                    name: '구매취소',
                    url:  '/howto/cancel'
                }
            ]
        },
        {
            name:     '고객센터',
            children: [
                {
                    name: '공지사항',
                    url:  '/post/notice'
                },
                {
                    name: 'FAQ',
                    url:  '/post/faq'
                }
            ]
        },
        {
            name: '내상품권',
            url:  '/certificate'
        }
    ]
    @observable isShowSubMenu: boolean
    @observable isShowHeader: boolean

    constructor(props) {
        super(props)
        this.isShowSubMenu = false
        this.isShowHeader  = false
    }

    componentDidMount() {
        this.isShowHeader = !this.$present(this.getQuery('__accessToken')) || this.$session.isMemberAlive
        if (this.isShowHeader) {
            ChannelInstance.start({ "pluginKey": "6899f34f-6c73-4605-a7f2-8d1145956c4a" })
        }
    }

    render() {
        return this.isShowHeader && <header className="goodpin-header">
            <TopBannerComponent/>
            <div className="menu-container">
                <div className="mx-1280">
                    <div className="row align-items-center">
                        <div className="col">
                            <Link to="/">
                                <img className="w-100px" src={ this.getImagePath('logo.png', true) }/>
                            </Link>
                        </div>
                        <div className="col-auto text-right">
                            <ul className="header-menu d-md-block d-none d-sm-none d-xl-block d-lg-block">
                                {
                                    _.map(this.menus, (menu, index) => {
                                        if (this.$present(menu.children)) {
                                            return <li key={ index }
                                                       onMouseEnter={ (e) => {
                                                           this.isShowSubMenu = true
                                                       } } onMouseLeave={ (e) => {
                                                this.isShowSubMenu = false
                                            } }>
                                                <Link className="text-dark fs-3_5" to={ menu.children[0].url }
                                                      onClick={ (e) => {
                                                          this.isShowSubMenu = false
                                                      } }>
                                                    { menu.name }
                                                </Link>
                                                {
                                                    this.isShowSubMenu && <div className="header-menu-child-container">
                                                        <ul className="header-menu-child mt-2">
                                                            {
                                                                _.map(menu.children, (child, index) => {
                                                                    return <li key={ index }>
                                                                        <Link className="text-dark fs-3_5"
                                                                              to={ child.url } onClick={ (e) => {
                                                                            this.isShowSubMenu = false
                                                                        } }>
                                                                            { child.name }
                                                                        </Link>
                                                                    </li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                            </li>
                                        } else {
                                            return <li key={ index }>
                                                <Link className="text-dark fs-3_5" to={ menu.url }>
                                                    { menu.name }
                                                </Link>
                                            </li>
                                        }
                                    })
                                }
                                <li>
                                    <Link className="border-round-button" to="/brand">
                                        상품권 구매하기
                                    </Link>
                                    {
                                        this.isOkGoodpinServiceTag &&
                                        <a className="border-round-button bg-danger border-danger ml-2"
                                           href="https://www.goodpin.co.kr">
                                            굿핀으로 이동
                                            <i className="mdi mdi-arrow-right"/>
                                        </a>
                                    }
                                </li>
                            </ul>
                            <div className="mobile-version d-md-none d-block d-sm-block d-xl-none d-lg-none">
                                <Link className="border-round-button mr-2 fs-2" to="/certificate">
                                    <i className="mdi mdi-view-list"/>
                                    <span className="ml-1">내상품권</span>
                                </Link>
                                <Link className="border-round-button fs-2" to="/brand">
                                    <i className="mdi mdi-certificate"/>
                                    <span className="ml-1">구매하기</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                this.isShowSubMenu && <div className="sub-menu-background">
                    <div className="sub-menu-white-background">
                    </div>
                </div>
            }
        </header>
    }
}

export const Header = withRouter(HeaderComponent)