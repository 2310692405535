import * as React from 'react'
import { ReactComponent } from 'support-js/react/component'

export class HowToBuy extends ReactComponent<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        return <div className="howto-buy-index">
            <div className="tab-section">
                <h3 className="tab-title text-center">APP화면</h3>
                <div className="row howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label">1</label>
                        <h5 className="tab-sub-title">
                            앱에서 구매할
                            <br/>
                            상품권 종류 선택
                        </h5>
                        <p className="tab-comment mt-4">
                            앱 메인에서 '상품권 구매' 클릭 후
                            <br/>
                            구매할 상품권 종류를 클릭합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/buy_app_1.png', true) } className="w-100"/>
                    </div>
                </div>
                <div className="row mt-5 howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label">2</label>
                        <h5 className="tab-sub-title">
                            상품권의 수량 및
                            <br/>
                            결제수단 선택
                        </h5>
                        <p className="tab-comment mt-4">
                            구매할 상품권 권종의 수량 및
                            <br/>
                            결제수단 선택 후 결제하기
                            <br/>
                            진행합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/buy_app_2.png', true) } className="w-100"/>
                    </div>
                </div>
                <div className="row mt-5 howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label">3</label>
                        <h5 className="tab-sub-title">
                            SMS로 받은 링크 클릭 후
                            <br/>
                            안내에 따라 결제
                        </h5>
                        <p className="tab-comment mt-4">
                            SMS로 받은 결제링크를 클릭합니다.
                            <br/>
                            안내에 따라 결제창에서 결제를 완료합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/buy_app_3.png', true) } className="w-100"/>
                    </div>
                </div>
            </div>
        </div>
    }
}