// @ts-ignore
// import { ConstStore, Constant } from 'stores/const.ts.erb'
import { Session, SessionStore } from 'stores/session'

export interface RootStoreInterface {
    // constant: ConstStore
    session: SessionStore
}

export const RootStore = {
    // constant: Constant,
    session: Session
} as RootStoreInterface