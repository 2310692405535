import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios'
import AES from 'crypto-js/aes'
import Base64 from 'crypto-js/enc-base64'
import { isPresent, isType } from 'support-js/support'
import $ from 'jquery'
import { generateUUID } from 'support-js/support'
import { DeviceStorage } from 'support-js/mixins/device'
import _ from 'lodash'
import { isProgress, showProgress, hideProgress } from 'support-js/react/components/boot-progress'

interface EncryptParamsFormat {
    payload: string
    token: string
}

class ReactResource {
    $http: AxiosInstance
    LockKeys: Array<string>

    constructor() {
        this.$http = axios
        this.LockKeys = []
    }

    static axiosBootpayConfigure() {
        // Class 내 construct 에서 여러번 호출될 수 있으므로 한번만 호출되도록 한다
        axios.defaults.headers.common['X-CSRF-Token'] = $('meta[name="csrf-token"]').attr('content')
        axios.defaults.headers.common['Content-Type'] = 'application/json'
        axios.defaults.headers.common['Accept'] = 'application/json'
        axios.interceptors.request.use((config: AxiosRequestConfig) => {
            const deviceId = DeviceStorage.getDeviceId()
            if (isPresent(config.data), this.isHttpBodyUsedMethod(config.method)) {
                config.data = this.encryptParams(config.data)
            }
            if (isPresent(deviceId)) {
                config.headers['GOODPIN-DEVICE-ID'] = deviceId
            }
            // 프로그래스창이 메세지가 있다면 보여준다
            if (isPresent(config.headers.progress)) {
                showProgress(config.headers.progress)
                delete config.headers.progress
            }
            // Do something before request is sent
            return config
        }, (error) => {
            // Do something with request error
            return Promise.reject(error)
        })

        axios.interceptors.response.use((response: AxiosResponse): any => {
            // 프로그래스창이 있다면 바로 삭제한다
            if (isProgress()) {
                hideProgress()
            }
            return response
        }, function (error) {
            if (isProgress()) {
                hideProgress()
            }
            if (isPresent(error.response)) {
                const responseData = _.merge({
                    status: error.response.status
                }, error.response.data)
                return Promise.reject(responseData)
            } else {
                return Promise.reject(error)
            }
        })
    }

    static isHttpBodyUsedMethod(method): boolean {
        return ['post', 'put'].indexOf(method) > -1
    }

    static encryptParams(data: Object): EncryptParamsFormat {
        if (isType(data, 'object')) {
            data = JSON.stringify(data)
        }
        let encryptData = AES.encrypt(data, generateUUID())
        return {
            payload: encryptData.ciphertext.toString(Base64),
            token: `${ encryptData.key.toString(Base64) }##${ encryptData.iv.toString(Base64) }`
        } as EncryptParamsFormat
    }

    static objectKeyToUnderscore(value: any): any {
        let cloneObject: any = undefined
        if (isPresent(value)) {
            const _this = this
            if (Array.isArray(value)) {
                cloneObject = []
                value.forEach((_value) => {
                    let childObject: any = {}
                    Object.keys(_value).forEach((key) => {
                        childObject[key.toUnderscore()] = _value[key]
                    })
                    cloneObject.push(childObject)
                })
            } else {
                cloneObject = {}
                Object.keys(value).forEach((key) => {
                    cloneObject[key.toUnderscore()] = value[key]
                })
            }
        }
        return cloneObject
    }

    static objectKeyToCamel(value: any): any {
        let cloneObject: any = undefined
        if (isPresent(value)) {
            const _this = this
            if (Array.isArray(value)) {
                cloneObject = []
                value.forEach((_value) => {
                    let childObject: any = {}
                    Object.keys(_value).forEach((key) => {
                        childObject[key.toCamelcase()] = _value[key]
                    })
                    cloneObject.push(childObject)
                })
            } else {
                cloneObject = {}
                Object.keys(value).forEach((key) => {
                    if (isType(value[key], 'object') && Object.keys(value[key]).length > 0) {
                        cloneObject[key.toCamelcase()] = this.objectKeyToCamel(value[key])
                    } else {
                        cloneObject[key.toCamelcase()] = value[key]
                    }
                })
            }
        }
        return cloneObject
    }

    async semaphoreExecute(key: string, block: Function) {
        if (this.LockKeys.indexOf(key) > -1) {
            return Promise.reject({
                code: 10,
                message: '중복 작업중입니다.'
            })

        } else {
            this.LockKeys.push(key)
            try {
                const response = await block()
                this.releaseSemaphore(key)
                return Promise.resolve(response)
            } catch (e) {
                this.releaseSemaphore(key)
                return Promise.reject(e)
            }
        }
    }

    releaseSemaphore(key: string) {
        const index = this.LockKeys.indexOf(key)
        return index > -1 ? this.LockKeys.splice(index, 1) : undefined
    }

    setDefaultHeader(key: string, value: string) {
        this.$http.defaults.headers.common[key] = value
    }
}

// 한번만 호출한다 static 함수로 ( import를 여러번 하더라도 호출을 여러번 안함 )
ReactResource.axiosBootpayConfigure()

export { ReactResource }