import { ReactResource } from 'support-js/react/resources'

export class MaintenanceResource extends ReactResource {
    constructor() {
        super()
    }

    /**
     * 서비스 점검 메세지를 가져온다
     * Comment by Gosomi
     * @date: 2021-09-10
     */
    async message() {
        return this.semaphoreExecute('message', () => {
            return this.$http.get('/maintenance')
        })
    }
}