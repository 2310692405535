import * as React from 'react'
import { ReactComponent } from 'support-js/react/component'

export class HowToSend extends ReactComponent<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        return <div className="howto-send-index">
            <div className="tab-section">
                <h3 className="tab-title text-center">APP에서 선물</h3>
                <div className="row howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label">1</label>
                        <h5 className="tab-sub-title">
                            결제완료 후
                            <br/>
                            수신 문자의 URL클릭
                        </h5>
                        <p className="tab-comment mt-4">
                            결제완료 후 수신된 문자의
                            <br/>
                            URL을 클릭하여 상품권을 확인합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/send-app-1.jpg') } className="w-100"/>
                    </div>
                </div>
                <div className="row mt-5 howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label">2</label>
                        <h5 className="tab-sub-title">
                            휴대폰 인증 후
                            <br/>
                            선물하기 클릭
                        </h5>
                        <p className="tab-comment mt-4">
                            상품권 소유주의 휴대폰 인증 후
                            <br/>
                            선물하기를 클릭합니다.
                            <br/>
                            <span className="text-danger fs-2">
                            * 선물 후에는 결제취소 및 개봉이 불가능합니다.
                        </span>
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/send-app-2.jpg') } className="w-100"/>
                    </div>
                </div>
            </div>
            <div className="tab-section">
                <h3 className="tab-title text-center">수신된 SMS에서 선물</h3>
                <div className="row howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label">1</label>
                        <h5 className="tab-sub-title">
                            앱 하단 메뉴에서
                            <br/>
                            내 상품권 클릭
                        </h5>
                        <p className="tab-comment mt-4">
                            앱 하단 메뉴에서 '내 상품권' 클릭 후
                            <br/>
                            개봉할 상품권을 선택합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/send-pc-1.jpg') } className="w-100"/>
                    </div>
                </div>
                <div className="row mt-5 howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label">2</label>
                        <h5 className="tab-sub-title">
                            휴대폰 인증 후
                            <br/>
                            선물하기 클릭
                        </h5>
                        <p className="tab-comment mt-4">
                            상품권 소유주의 휴대폰 인증 후
                            <br/>
                            선물하기를 클릭합니다.
                            <br/>
                            <span className="text-danger fs-2">
                            * 선물 후에는 결제취소 및 개봉이 불가능합니다.
                        </span>
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/send-pc-2.jpg') } className="w-100"/>
                    </div>
                </div>
            </div>
        </div>
    }
}