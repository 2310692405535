import { ReactResource } from 'support-js/react/resources'

export interface RequestPayment {
    rp: string
    bp: string
    online?: number,
    step: number,
    safe?: number
    order: Array<any>
    prepaid: number
    price: number
}

export class PurchaseResource extends ReactResource {
    constructor() {
        super()
    }

    /**
     * 상품권 구매 정보를 가져온다
     * Comment by Gosomi
     * @date: 2021-09-08
     * @param id: string
     */
    async purchaseCompanyData(id: string) {
        return this.semaphoreExecute('purchaseCompanyData', async () => {
            return this.$http.get(`/purchase/${ id }`, {
                params: {
                    type: 'company'
                }
            })
        })
    }

    /**
     * 결제 정보를 요청한다
     * Comment by Gosomi
     * @date: 2021-09-08
     * @param
     * @returns
     */
    async requestPayment(data: RequestPayment) {
        return this.semaphoreExecute('requestPayment', async () => {
            return this.$http.post(`/request/payment`, data)
        })
    }

    /**
     * 상품권 하나의 정보를 가져온다
     * Comment by Gosomi
     * @date: 2021-09-11
     * @param id: string
     */
    async purchaseTypeData(id: string) {
        return this.semaphoreExecute('purchaseTypeData', async () => {
            return this.$http.get(`/purchase/${ id }`, {
                params: {
                    type: 'type'
                }
            })
        })
    }
}