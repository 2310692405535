import * as React from 'react'
import { Route } from 'react-router'
import { Brand } from 'components/brand'
import { Certificate } from 'components/certificate'
import { Introduce } from 'components/introduce'
import { Main } from 'components/main'
import { Maintenance } from 'components/maintenance'
import { Patent } from 'components/patent'
import { Howto } from 'components/howto'
import { Post } from 'components/post'
import { AppBrand } from 'components/app/brand'
import { AppPreference } from 'components/app/preference'
import { Terms } from 'components/terms'

export const Routes = () => {
    return (
        <>
            <Route exact path="/" component={ Main }/>
            <Route path="/app/preference" component={ AppPreference }/>
            <Route path="/app/brand" component={ AppBrand }/>
            <Route path="/brand" component={ Brand }/>
            <Route path="/certificate" component={ Certificate }/>
            <Route path="/howto/:id" component={ Howto }/>
            <Route path="/introduce" component={ Introduce }/>
            <Route path="/maintenance" component={ Maintenance }/>
            <Route path="/patent" component={ Patent }/>
            <Route path="/post/:id" component={ Post }/>
            <Route exact path="/terms" component={ Terms }/>
        </>
    )
}