import React from 'react'
import 'stylesheets/brand'
import { inject, observer } from 'mobx-react'
import { computed, observable } from 'mobx'
import { BrandResource } from 'resources/brand'
import _ from 'lodash'
import $ from 'jquery'
import { OnlinePurchase } from 'components/brand/online-purchase'
import { GiftPurchase } from 'components/brand/gift-purchase'
import { GoodpinComponent } from 'mixins/goodpin'

@inject('store')
@observer
export class Brand extends GoodpinComponent {
    @observable bestBrands
    @observable types
    @observable companies
    @observable count
    @observable isShowGetMore
    @observable search
    @observable categories
    @observable isShowBrand
    @observable isSearchCategories
    @observable isSearchBrand
    @observable isShowOrder
    @observable page: number
    @observable accessToken: string | undefined
    @observable showBestGuide: boolean
    @observable showBrandGuide: boolean
    @observable appSearchBar
    @observable onLoad: boolean
    @observable moreBrand: boolean
    onlinePurchase
    giftPurchase

    constructor(props) {
        super(props)
        this.setResource<BrandResource>(new BrandResource())
        this.bestBrands = []
        this.types = []
        this.count = 0
        this.companies = []
        this.search = {
            tag: '',
            categories: [],
            companyIds: [],
            order: '순'
        }
        this.categories = []
        this.isShowGetMore = true
        this.onlinePurchase = undefined
        this.giftPurchase = undefined
        this.isShowBrand = false
        this.isSearchCategories = true
        this.isSearchBrand = false
        this.isShowOrder = false
        this.accessToken = undefined
        this.showBestGuide = true
        this.showBrandGuide = true
        this.moreBrand = false
        this.appSearchBar = false
        this.page = 0
        this.onLoad = false
        this.$template = require('brand')
    }

    /**
     * 페이지에서 돌아온 이후 스크롤 조정
     * Comment by Gosomi
     * @date: 2021-10-06
     * @param scrollY: number
     */
    setScroll(scrollY: number) {
    }

    async componentDidMount() {
        location.hash = ''
        $('html').addClass('x-hidden')
        $('body').addClass('x-hidden')
        // access token을 넣는 작업을 한다
        await this.setAccessToken()
        this.$template = require('brand')
        this.basicBrand()
        if (this.$present(this.props.location.state) && this.$present(this.props.location.state.giftCompanyId)) {
            // 바로 상품권 정보를 보여주도록 변경
            this.onLoad = true
            if (this.props.location.state.displayType === 1) {
                await this.onlinePurchase.showOnlinePurchase(this.props.location.state.giftCompanyId)
            } else {
                this.search.companyIds.push(this.props.location.state.giftCompanyId)
            }
        }
        return this.reloadBrands()
    }

    @computed
    get isApp() {
        return this.$present(this.accessToken)
    }

    /**
     * 회사 정보를 리턴
     * Comment by Gosomi
     * @date: 2021-09-09
     */
    companyById(companyId: string) {
        const company = _.filter(this.companies, (v) => v.gift_company_id === companyId)
        return this.$present(company) ? company.first() : undefined
    }

    /**
     * 회사 정보중 일부 정보를 노출
     * Comment by Gosomi
     * @date: 2021-09-09
     */
    companyDataByKey(companyId: string, key: string, defaultValue: any = '') {
        return this.$present(this.companyById(companyId)) ? this.$presence(this.companyById(companyId)[key], defaultValue) : defaultValue
    }

    @computed
    get lastTypeId() {
        return this.$present(this.types) && this.types.length > 0 ? this.types.last().gift_type_id : undefined
    }

    @computed
    get filterBestBrands() {
        const namespaces = _.map(this.bestBrands, (v) => v.namespace)
        return this.moreBrand ? _.filter(this.companies, (company) => {
            return namespaces.indexOf(company.namespace) === -1
        }) : []
    }

    /**
     * 브랜드 정보를 갱신한다
     * Comment by Gosomi
     * @date: 2021-09-07
     */
    async reloadBrands(refresh = false, searchFocus = false) {
        if (refresh) {
            this.types = []
            this.page = 1
        } else {
            this.page += 1
        }
        if (/문화|해피|틴캐시|구글|도서|퍼니|티캐시|컬쳐/.test(this.search.tag) || this.search.categories.indexOf('온라인상품권') > -1) {
            this.onLoad = true
            return this.preFilterOnlineGift()
        }

        try {
            const response = await this.$resource.brands(
                this.search.tag,
                this.search.categories,
                this.search.companyIds,
                this.search.order,
                this.page
            )
            this.onLoad = true
            const { list, count } = response.data
            if (this.$blank(this.types) || this.types.length === 0) {
                this.count = count
            }
            this.types = this.types.concat(list)
            this.isShowGetMore = (this.count !== 0 && this.types.length) && this.count > this.types.length
        } catch (e) {
            this.onLoad = true
            if (e.code !== 10) {
                return this.$alert(e.message)
            }
        }
        if (refresh && searchFocus) {
            const currentBodyScrollY = $('body').scrollTop()
            const goodContentOffsetY = $('#good-content').offset().top
            $('body').scrollTop(currentBodyScrollY + goodContentOffsetY - 20)
        }
    }

    // 결제창을 보여주거나 검색창으로 넘긴다
    showPurchaseWindow(brand) {
        // 핀코드 발급 방식
        if (brand.display_type === 1) {
            this.onlinePurchase.showOnlinePurchase(brand.gift_company_id)
        } else {
            this.search.tag = ''
            this.search.categories = []
            this.search.companyIds = [brand.gift_company_id]
            return this.reloadBrands(true, true)
        }
    }

    /**
     * 기본 정보를 로드한다
     * Comment by Gosomi
     * @date: 2021-09-09
     */
    async basicBrand() {
        try {
            const response = await this.$resource.basicBrand()
            const { best_brands, companies, categories } = response.data
            this.bestBrands = best_brands
            this.companies = companies
            this.categories = categories
        } catch (e) {
            return this.$alert(e.message)
        }
    }

    /**
     * 검색어 PreFilter 기능
     * Comment by Gosomi
     * @date: 2021-09-27
     */
    preFilterOnlineGift() {
        if (this.search.categories.indexOf('온라인상품권') > -1 && this.$blank(this.search.tag)) {
            this.types = _.filter(this.bestBrands, (v) => {
                v.tag = 0
                return v.display_type === 1
            })
        } else {
            this.types = _.filter(this.bestBrands, (v) => {
                v.tag = 0
                return (new RegExp(`${ this.search.tag }`)).test(v.name)
            })
        }
        this.count = this.types.length
        this.isShowGetMore = false
    }

    render() {
        return super.render({
            OnlinePurchase: OnlinePurchase,
            GiftPurchase: GiftPurchase,
            '$': $
        })
    }

}