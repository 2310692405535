import * as React from 'react'
import { ReactComponent } from 'support-js/react/component'
import { Link } from 'react-router-dom'
import 'stylesheets/introduce'

export class Introduce extends ReactComponent<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        return <div className="introduce-index goodpin-container">
            <div className="goodpin-page-title">
                <div className="title-container">
                    <div className="font-weight-light">구매하는 순간부터 사용할 때 까지</div>
                    <div className="font-weight-bold">안전하게 보호되는 굿핀</div>
                </div>
            </div>
            <div className="mx-1280">
                <div className="pt-5 pb-5">
                    <div className="row align-items-stretch p-0 m-0">
                        <div className="col-sm-6 p-0 m-0">
                            <img src={ this.getImagePath('introduce/introduce_1.png', true) } className="w-100"/>
                        </div>
                        <div className="col-sm-6 p-0 m-0 text-dark text-center bg-gray full-column">
                            <div className="text-container">
                                <h4>믿을 수 있는 업체, 특허받은 서비스</h4>
                                <div className="mt-3 fs-3 text-gray">
                                    굿핀은 누적거래 약 300,000건으로 단 한번의 거래사고가 없었으며,
                                    <br/>
                                    국내최초 특허 신기술 보유로 안심하고 사용할 수 있고
                                    <br/>
                                    믿고 안전하게 구매하셔도 됩니다.
                                </div>
                                <div className="mt-3">
                                    <Link className="text-danger fs-3 font-weight-bold btn-link" to="/">
                                        자세히보기 <i className="mdi mdi-arrow-right"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-stretch mt-3 p-0 m-0">
                        <div className="col-sm-6 p-0 m-0 text-center bg-gray full-column">
                            <div className="text-container">
                                <h4>국내 업계 최저 수수료 보장</h4>
                                <div className="mt-3 fs-3 text-gray">
                                    가장 저렴한 수수료로 상품권을 구매하세요.
                                    <br/>
                                    굿핀이 업계 최저 수수료를 보장합니다
                                    <br/>
                                    특히 휴대폰 소액결제의 경우 유수의 업체로 자리매김중입니다.
                                    <br/>
                                    아이폰의 경우 휴대폰 결제가 가능한 유일한 업체입니다.
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 p-0 m-0 text-dark text-center">
                            <img src={ this.getImagePath('introduce/introduce_2.png', true) } className="w-100"/>
                        </div>
                    </div>
                    <div className="row align-items-stretch mt-3 p-0 m-0">
                        <div className="col-sm-6 p-0 m-0">
                            <img src={ this.getImagePath('introduce/introduce_3.png', true) } className="w-100"/>
                        </div>
                        <div className="col-sm-6 p-0 m-0 text-dark text-center bg-gray full-column">
                            <div className="text-container">
                                <h4>가입이 필요없는 간편 서비스</h4>
                                <div className="mt-3 fs-3 text-gray">
                                    굿핀은 서비스 사용시 회원가입 절차없이
                                    <br/>
                                    SMS인증만 하면 누구나 간편하게 이용하실 수 있습니다.
                                </div>
                                <div className="mt-3">
                                    <Link className="text-danger fs-3 font-weight-bold btn-link" to="/">
                                        이용하기 <i className="mdi mdi-arrow-right"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-5 pb-5 bg-pink">
                <div className="mx-1280 text-center">
                    <div className="fs-3 text-danger font-weight-bold">
                        상품권 어디까지 써봤니?
                    </div>
                    <div className="text-dark fs-36 font-weight-bold mt-3">
                        '선물하기'까지 가능한 굿핀
                    </div>
                    <p className="mt-3 fs-3 text-gray">
                        상품권 구매 요청 후 결제를 완료하면 구매자의 휴대폰으로 상품권 핀 번호가 포함된 링크가 SMS로 전송됩니다.
                        <br/>
                        상품권을 사용하고자하는 경우 구매자가 본인인증을 거쳐 상품권을 개봉하면 핀번호가 오픈됩니다.
                        <br/>
                        선물하기는 개봉이 되지 않은 상태에서 상품권 링크를 타인에게 SMS로 바로 전송하면 됩니다.
                    </p>
                    <div className="row align-items-center mt-5">
                        <div className="col-4">
                            <div className="circle square">
                                <div className="circle-container">
                                    <img src={ this.getImagePath('introduce/ico_giftcard.png', true) }/>
                                    <div className="mt-3 font-weight-bold fs-4">
                                        구매하기
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="circle square">
                                <div className="circle-container">
                                    <img src={ this.getImagePath('introduce/ico_giftcard_used.png', true) }/>
                                    <div className="mt-3 font-weight-bold fs-4">
                                        사용하기
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="circle square">
                                <div className="circle-container">
                                    <img src={ this.getImagePath('introduce/ico_giftcard_sent.png', true) }/>
                                    <div className="mt-3 font-weight-bold fs-4 text-danger">
                                        선물하기
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-5 pb-5 bg-gray">
                <div className="mx-1280">
                    <div className="pt-5 pb-5">
                        <div className="row align-items-stretch mt-3 p-0 m-0">
                            <div className="col-sm-6 p-0 m-0 text-center bg-white full-column">
                                <div className="text-container">
                                    <div className="icon">
                                        <img src={ this.getImagePath('icon/ico_comma.png', true) }/>
                                    </div>
                                    <div className="mt-3 fs-3_5 text-dark font-weight-bold">
                                        경찰 관계자는 "해당 서버의 보안시스템은 자체 방화벽이 없을 정도로 허술 했고, 상품권 발송 데이터베이스에는 상품권정보가 암호화되지 않은상태로
                                        저장돼 일련번호와 PIN이 그대로 노출됐다"고 말했다. (중간생략)
                                        <br/>
                                        <br/>
                                        "일련번호와 PIN만으로 상품권을 무단 사용하는 것을 막으려면 바코드 등 상품권 증표를 제시하게하고, 상품권이 사용되면 구매자에게 문자메세지로
                                        내역이 전송되게 하는 등 규정을 마련할 필요가 있다"고 말했다.
                                    </div>
                                    <div className="mt-3 fs-3 text-gray">
                                        "홈플러스 모바일 상품권 무단사용, 알고보니 中 해커 소행” (연합뉴스) 2015.08.02
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 p-0 m-0 text-dark text-center">
                                <img src={ this.getImagePath('introduce/introduce_4.png', true) } className="w-100"/>
                            </div>
                        </div>
                        <div className="row align-items-stretch p-0 m-0 mt-3">
                            <div className="col-sm-6 p-0 m-0">
                                <img src={ this.getImagePath('introduce/introduce_5.png', true) } className="w-100"/>
                            </div>
                            <div className="col-sm-6 p-0 m-0 text-dark text-center bg-white full-column">
                                <div className="text-container">
                                    <h4>사용 전까지 보호되는 핀 번호</h4>
                                    <div className="mt-3 fs-3 text-gray">
                                        굿핀에서 판매 및 유통하는 상품권은 타 업체와 같이 SMS나 E-email로
                                        <br/>
                                        핀번호만 전달되지 않습니다. 특허 출원된 자사만의 시스템으로
                                        <br/>
                                         사용전까지 핀 번호가 노출되지 않은 상태로 유통됩니다.
                                    </div>
                                    <div className="mt-3">
                                        <Link className="text-danger fs-3 font-weight-bold btn-link" to="/">
                                            자세히보기 <i className="mdi mdi-arrow-right"/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className="customer bg-white">
                    <div className="inner">
                        <strong>무엇이든 물어보세요</strong>
                        <div>
                            <a className="text-danger" href="tel:1855-2653"><span></span>1855-2653</a>
                            <p><span>평일</span>09:00 ~ 18:00<span>휴무</span>주말/공휴일</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    }
}