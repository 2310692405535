import * as React from 'react'
import { observer } from 'mobx-react'
import { ReactComponent } from 'support-js/react/component'
import { observable } from 'mobx'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { HowToBuy } from 'components/howto/buy'
import { HowToCancel } from 'components/howto/cancel'
import { HowToConfirm } from 'components/howto/confirm'
import { HowToSend } from 'components/howto/send'
import { HowToList } from 'components/howto/list'
import { HowToMobile } from 'components/howto/mobile'

@observer
export class Howto extends ReactComponent<any, any> {
    tabIndexes = [
        'mobile',
        'buy',
        'confirm',
        'send',
        'list',
        'cancel'
    ]
    tabIndexesKo = [
        '모바일 쿠폰 구매하기',
        '구매하기',
        '상품권확인',
        '선물하기',
        '구매내역 확인',
        '구매취소'
    ]
    @observable tabId
    @observable isShow

    constructor(props) {
        super(props)
        this.tabId = props.match.params.id
        this.isShow = false
    }

    async componentDidMount() {
        if (this.tabIndexes.indexOf(this.tabId) === -1) {
            await this.$alert('잘못된 접근입니다.')
            history.go(-1)
        } else {
            this.isShow = true
        }
    }

    async componentHistoryChange(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        this.tabId = this.props.match.params.id
    }

    /**
     * 활성화된 탭인지 체크
     * Comment by Gosomi
     * @date: 2021-08-18
     * @param index: number
     * @returns boolean
     */
    isActiveTab(index: number) {
        return index === this.tabIndexes.indexOf(this.tabId)
    }

    /**
     * 링크 정보를 리턴한다
     * Comment by Gosomi
     * @date: 2021-08-18
     * @param index: number
     * @returns string
     */
    howtoLink(index: number) {
        return `/howto/${ this.tabIndexes[index] }`
    }

    render() {
        return this.isShow && <div className="howto-index goodpin-container">
			<div className="goodpin-page-title">
				<div className="title-container">
					<div className="font-weight-bold">이용안내</div>
				</div>
			</div>
			<div className="content-container border-0 p-0 vertical-menu">
				<div className="mx-1280">
					<div className="tab-indexes row text-center">
                        {
                            _.map(this.tabIndexesKo, (tab, index) => {
                                return <div className={ `col w-20 ${ this.isActiveTab(index) ? 'active' : '' }` }
                                            key={ index }>
                                    <Link to={ this.howtoLink(index) }>
                                        { tab }
                                    </Link>
                                </div>
                            })
                        }
					</div>
					<div className="tab-content">
                        { this.tabId === 'mobile' && <HowToMobile/> }
                        { this.tabId === 'buy' && <HowToBuy/> }
                        { this.tabId === 'confirm' && <HowToConfirm/> }
                        { this.tabId === 'send' && <HowToSend/> }
                        { this.tabId === 'list' && <HowToList/> }
                        { this.tabId === 'cancel' && <HowToCancel/> }
					</div>
				</div>
			</div>
		</div>
    }
}