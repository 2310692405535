import { ReactResource } from 'support-js/react/resources'

export class NoticeResource extends ReactResource {
    constructor() {
        super()
    }

    /**
     * Notice 데이터를 가져온다
     * Comment by Gosomi
     * @date: 2021-08-18
     * @param noticeId: string
     * @param lastId: string | undefined
     * @param search: string | undefined
     */
    async getNoticeList(noticeId: string, lastId: string | undefined, search: string | undefined) {
        return this.semaphoreExecute('getNoticeList', async () => {
            return this.$http.get(
                `/post/${ noticeId }`,
                {
                    params: {
                        last_id: lastId,
                        search: search
                    }
                }
            )
        })
    }
}