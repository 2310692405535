import React from 'react'
import { GoodpinComponent } from 'mixins/goodpin'
import { inject, observer } from 'mobx-react'
import { BrandResource } from 'resources/brand'
import { observable } from 'mobx'

@inject('store')
@observer
export class AppPreference extends GoodpinComponent {
    @observable version

    constructor(props) {
        super(props)
        this.$template = require('app/preference')
        this.version = this.getQuery('ver')
        this.setResource<BrandResource>(new BrandResource())
    }

    async componentDidMount() {
        await this.setAccessToken()
    }

    /**
     * 앱 로그아웃 기능
     * Comment by Gosomi
     * @date: 2021-09-29
     */
    async appLogout() {
        await this.$confirm(
            '로그아웃을 할까요?',
            '로그아웃'
        )
        try {
            await this.$resource.appLogout()
            return this.$callApp({
                success: true,
                message: '로그아웃이 완료되었습니다.'
            })
        } catch (e) {
            return this.$alert(e.message)
        }
    }

    /**
     * 동의 철회
     * Comment by Gosomi
     * @date: 2022-11-17
     */
    async drawPrivatePolicy() {
        await this.$confirm(
            `<div class="text-left">개봉 인증 정보가 모두 초기화가 되며, 인증시 저장하고 있던 모든 정보가 초기화됩니다.<br/><br/>또한, <span class="text-danger font-weight-bold">재구매시 인증을 다시 처음부터 받아야 상품권을 개봉</span>할 수 있습니다.<br/><br/><span class="text-danger font-weight-bold">개인정보 내역 동의한 모든 내역을 삭제하시겠습니까?</span></div>`,
            '개인정보 삭제'
        )
        try {
            await this.$resource.drawPrivatePolicy()
            return this.$callApp({
                success: true,
                message: '모든 개인정보가 삭제 및 초기화가 되었습니다. 그동안 굿핀을 이용해주셔서 감사합니다.'
            })
        } catch(e) {
            return this.$alert(e.message)
        }
    }

    render() {
        return super.render()
    }
}