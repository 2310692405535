import * as React from 'react'
import { observer } from 'mobx-react'
import { ReactComponent } from 'support-js/react/component'

@observer
export class Patent extends ReactComponent<any, any> {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return <div className="goodpin-container patent-index">
            <div className="goodpin-page-title">
                <div className="title-container">
                    <div className="font-weight-bold">특허 내역</div>
                </div>
            </div>
            <div className="content-container patent-content">
                <div className="mx-1280">
                    <h4 className="content-title text-center">
                        국내 최초 특허 신기술 보유로 앞서나갑니다
                    </h4>
                    <div className="row mt-5 patent-inner-content">
                        <div className="col-md-auto text-center">
                            <img src={ this.getImagePath('patent/patent.png') } className="w-300px"/>
                        </div>
                        <div className="col-md">
                            <div className="mt-3">
                                <span className="text-gray">특허명</span>
                                <span className="text-dark ml-3">
                                    전자상품권 판매 서버, 그 방법 (THE SERVER AND METHOD FOR SELLING GIFT CERTIFICATE)
                                </span>
                            </div>
                            <div className="mt-3">
                                <div className="row">
                                    <div className="col-6">
                                        <span className="text-gray">출원번호/일자</span>
                                        <span className="text-dark ml-3">
                                            1020150097965 (2015.07.09)
                                        </span>
                                    </div>
                                    <div className="col-6">
                                        <span className="text-gray">등록번호/일자</span>
                                        <span className="text-dark ml-3">
                                            1017237340000 (2017.03.30)
                                        </span>
                                    </div>
                                </div>
                                <div className="mt-3 text-gray">
                                    본 발명은 무선 또는 유선으로 데이터 전송이 가능한 하나 이상의 단말기와 연결된 서버에 있어서, 특정 단말기로부터 수신 대상 MDN 정보가 입력되고
                                    결제가 완료되면 입력된 수신 대상 MDN 정보에 해당하는 단말기로 전자상품권 URL을 송신하는 URL 송신 수단 인증번호가 입력되어 생성된 전자상품권
                                    사용 정보를 상기 전자상품권 URL을 통해 접속된 단말기로부터 수신하는 수신 수단 및 상기 수신 수단에서 수신된 상기 전자상품권 사용 정보 중 상기
                                    인증번호가 상기 전자상품권 URL을 통해 접속된 단말기에 미리 설정된 인증번호와 일치하면 전자상품권 정보를 송신하는 전자상품권 송신 수단을 포함하는
                                    전자상품권 판매 서버, 그 방법에 관한 것이다.
                                    <br/>
                                    <br/>
                                    본 발명에 따르면, 서버가 전자상품권 URL을 해당 단말기로 송신하고 해당 단말기가 전자상품권 URL로 접속 후 인증번호를 입력하면 서버가 해당
                                    단말기에 미리 설정된 인증번호와 입력받은 인증번호를 서로 비교하여 일치하면 해당 단말기의 MDN으로 사용 가능한 전자상품권을 송신하기 때문에 전자상품권
                                    URL이 해킹되더라도 인증번호가 불일치하면 전자상품권을 수신받지 못하게하는 보안성이 강화된 전자상품권 판매 서버, 그 방법을 제공할 수 있다.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-container patent-process-content">
                <div className="mx-1280">
                    <div className="row">
                        <div className="col-md-auto w-300px">
                            <h4 className="content-title">
                                사용하기(개봉하기)
                                <br/>
                                프로세스
                            </h4>
                        </div>
                        <div className="col-md">
                            <div className="row align-items-center">
                                <div className="col-auto position-relative">
                                    <div className="label-box text-dark font-weight-bold">미개봉 상품권 조회</div>
                                    <div className="arrow-down">
                                        <i className="mdi mdi-chevron-double-down"/>
                                    </div>
                                </div>
                                <div className="col fs-3 text-gray">
                                    - 핀번호가 개봉되기 전 상태의 상품권을 조회할 수 있습니다.
                                </div>
                            </div>
                            <div className="row mt-5 align-items-center">
                                <div className="col-auto position-relative">
                                    <div className="label-box text-dark font-weight-bold">사용하기 선택</div>
                                    <div className="arrow-down">
                                        <i className="mdi mdi-chevron-double-down"/>
                                    </div>
                                </div>
                                <div className="col fs-3 text-gray">
                                    - 상품권의 사용 여부를 선택할 수 있습니다.
                                </div>
                            </div>
                            <div className="row mt-5 align-items-center">
                                <div className="col-auto position-relative">
                                    <div className="label-box text-dark font-weight-bold">인증 SMS</div>
                                    <div className="arrow-down">
                                        <i className="mdi mdi-chevron-double-down"/>
                                    </div>
                                </div>
                                <div className="col fs-3 text-gray">
                                    - 인증번호는&nbsp;
                                    <span className="text-danger">최종 소지자의 휴대폰 번호로 자동전송</span>
                                    됩니다.
                                </div>
                            </div>
                            <div className="row mt-5 align-items-center">
                                <div className="col-auto position-relative">
                                    <div className="label-box text-dark font-weight-bold">인증번호 입력</div>
                                    <div className="arrow-down">
                                        <i className="mdi mdi-chevron-double-down"/>
                                    </div>
                                </div>
                                <div className="col fs-3 text-gray">
                                    - 인증번호를 입력합니다.
                                </div>
                            </div>
                            <div className="row mt-5 align-items-center">
                                <div className="col-auto position-relative">
                                    <div className="label-box text-dark font-weight-bold">핀 번호가 개봉된 상품권 조회</div>
                                </div>
                                <div className="col fs-3 text-gray">
                                    - 개봉된 핀번호를 조회할 수 있습니다.
                                    <br/>
                                    <span className="text-danger font-weight-bold fs-1">* 핀번호가 개봉된 상품권을 취소 및 선물하기 기능이 제한됩니다.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-container patent-process-content">
                <div className="mx-1280">
                    <div className="row mt-5">
                        <div className="col-md-auto w-300px">
                            <h4 className="content-title">
                                선물하기 프로세스
                            </h4>
                        </div>
                        <div className="col-md">
                            <div className="row align-items-center">
                                <div className="col-auto position-relative">
                                    <div className="label-box text-dark font-weight-bold">미개봉 상품권 조회</div>
                                    <div className="arrow-down">
                                        <i className="mdi mdi-chevron-double-down"/>
                                    </div>
                                </div>
                                <div className="col fs-3 text-gray">
                                    - 핀번호가 개봉되기 전 상태의 상품권을 조회할 수 있습니다.
                                </div>
                            </div>
                            <div className="row mt-5 align-items-center">
                                <div className="col-auto position-relative">
                                    <div className="label-box text-dark font-weight-bold">선물하기 선택</div>
                                    <div className="arrow-down">
                                        <i className="mdi mdi-chevron-double-down"/>
                                    </div>
                                </div>
                                <div className="col fs-3 text-gray">
                                    - 상품권의 선물하기를 선택합니다.
                                </div>
                            </div>
                            <div className="row mt-5 align-items-center">
                                <div className="col-auto position-relative">
                                    <div className="label-box text-dark font-weight-bold">인증 SMS</div>
                                    <div className="arrow-down">
                                        <i className="mdi mdi-chevron-double-down"/>
                                    </div>
                                </div>
                                <div className="col fs-3 text-gray">
                                    - 인증번호는&nbsp;
                                    <span className="text-danger">구매시 사용한 휴대폰 번호로 자동 전송</span>
                                    됩니다.
                                </div>
                            </div>
                            <div className="row mt-5 align-items-center">
                                <div className="col-auto position-relative">
                                    <div className="label-box text-dark font-weight-bold">인증번호 입력</div>
                                    <div className="arrow-down">
                                        <i className="mdi mdi-chevron-double-down"/>
                                    </div>
                                </div>
                                <div className="col fs-3 text-gray">
                                    - 인증번호를 입력합니다.
                                </div>
                            </div>
                            <div className="row mt-5 align-items-center">
                                <div className="col-auto position-relative">
                                    <div className="label-box text-dark font-weight-bold">SMS 발송</div>
                                </div>
                                <div className="col text-gray">
                                    - 선물받는 사람에게 상품권 URL이 SMS로 전송됩니다.
                                    <br/>
                                    <span className="text-danger font-weight-bold fs-1">* SMS 전송시 상품권 URL이 변경되어 전송되므로 타인의 접근이 차단됩니다.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className="customer">
                    <div className="inner">
                        <strong>무엇이든 물어보세요</strong>
                        <div>
                            <a className="text-danger" href="tel:1855-2653"><span></span>1855-2653</a>
                            <p><span>평일</span>09:00 ~ 18:00<span>휴무</span>주말/공휴일</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    }
}