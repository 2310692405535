import { computed, observable } from 'mobx'
import { ReactResource } from 'support-js/react/resources'
import { isPresent } from 'support-js/support'

interface SessionOption {
    pk?: string
    force?: boolean
}

export class SessionStore extends ReactResource {
    @observable member
    @observable provider
    @observable app
    @observable app_role
    @observable pk
    @observable menus
    @observable token

    constructor() {
        super()
        this.initSession()
    }

    initSession() {
        this.member = undefined
        this.provider = undefined
        this.app = undefined
        this.app_role = undefined
        this.menus = []
        this.token = ''
        this.pk = ''
    }

    /**
     * 현재 멤버 상태를 리턴
     * Comment by Gosomi
     * @date: 2021-04-29
     */
    @computed
    get currentMember() {
        return this.member
    }

    @computed
    get isMemberAlive() {
        return isPresent(this.member)
    }

    setSession(session) {
        this.member = session
    }

    /**
     * 세션 정보를 가져온다
     * Comment by Gosomi
     * @date: 2021-04-30
     */
    async getFetchSession() {
        try {
            const response = await this.$http.get('/session')
            return Promise.resolve(response)
        } catch (e) {
            return Promise.reject(e)
        }
    }

    /* 프로젝트 선택 전, 멤버 세션 필요 시 호출 | comment by Alfred | date 2021/05/17 */
    async getFetchSessionMemberOnly() {
        try {
            const response = await this.$http.get('/session/member_session')
            return Promise.resolve(response)
        } catch (e) {
            return Promise.reject(e)
        }
    }

    async alive(options: SessionOption = {}) {
        if (isPresent(options.pk)) {
            this.pk = options.pk
        }
        if (!options.force && isPresent(this.member)) {
            return Promise.resolve(this.member)
        } else {
            try {
                const response = await this.getFetchSession()
                const { member, provider, app, app_role, menus } = response.data
                this.member = member
                this.provider = provider
                this.app = app
                this.app_role = app_role
                this.menus = menus || []
                return Promise.resolve(this.member)
            } catch (e) {
                return Promise.reject(e)
            }
        }
    }

    /**
     * 프로젝트 선택전, 세션 필요 시 호출,
     * alive() 대용
     * @comment by Alfred
     * @date 2021-05-17, 09:59
     */

    async memberAlive() {
        try {
            const response = await this.getFetchSessionMemberOnly()
            const { member } = response.data
            this.member = member
            return Promise.resolve(this.member)
        } catch (e) {
            return Promise.reject(e)
        }
    }

    /**
     * 로그아웃
     * @comment by Alfred
     * @date 2021-05-06, 10:47
     */
    async destroySession() {
        try {
            const response = await this.$http.delete("/session/logout")
            location.href = "/login"
            this.initSession()
            return Promise.resolve(response)
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

export const Session = new SessionStore()