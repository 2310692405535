import { Routes } from 'routes'
import React from 'react'
import { observer, Provider } from 'mobx-react'
import { BootAlert, bootAlertRef } from 'support-js/react/components/boot-alert'
import { RootStore } from 'stores/root'
import { BootProgress, bootProgressRef } from 'support-js/react/components/boot-progress'
import { BootNotifyContainer, bootNotifyRef } from 'support-js/react/components/boot-notify'
import { BrowserRouter } from 'react-router-dom'
import { Header } from 'components/header'
import { Footer } from 'components/footer'
import { Policy, PolicyRef } from 'components/policy'
import $ from 'jquery'

export const App = observer((props) => {
    const serviceTag = $('meta[name="service-tag"]').attr('content')
    const rootClass  = serviceTag === undefined || serviceTag === 'GOODPIN' ?
        'origin' : serviceTag.replace(/GOODPIN_/g, '').toLowerCase()
    return (
        <Provider store={ RootStore }>
            <BootAlert ref={ bootAlertRef }/>
            <BootProgress ref={ bootProgressRef }/>
            {/*<BootNotifyContainer ref={ bootNotifyRef }/>*/}
            <Policy ref={ PolicyRef }/>
            <BrowserRouter>
                <div id="goodpin-main" className={ rootClass }>
                    <Header/>
                    <Routes/>
                    <Footer/>
                </div>
            </BrowserRouter>
        </Provider>
    )
})