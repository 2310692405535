import React from 'react'
import { GoodpinComponent } from 'mixins/goodpin'

export class Terms extends GoodpinComponent {
    policyTemplate: string

    constructor(props) {
        super(props)
        this.$template = require('terms')
        this.policyTemplate = require('policy')
    }

    render() {
        return super.render()
    }
}