import * as React from 'react'
import { observer } from 'mobx-react'
import { ReactComponent } from 'support-js/react/component'
import { observable } from 'mobx'
import { MainResource } from 'resources/main'
import $ from 'jquery'
import _ from 'lodash'
import AOS from 'aos'
import 'stylesheets/main'

@observer
export class Main extends ReactComponent<any, any> {
    @observable companies
    @observable imageVector
    imageVectorOrigin
    sliders

    constructor(props) {
        super(props)
        this.$template = require('main')
        this.setResource<MainResource>(new MainResource())
        this.companies = []
        this.sliders = [
            this.getImagePath('slide/main1.jpg'),
            this.getImagePath('slide/main2.jpg'),
            this.getImagePath('slide/main3.jpg'),
            this.getImagePath('slide/main4.jpg')
        ]
        this.imageVectorOrigin = [
            {
                id: 'slide01',
                start: {
                    left: 320,
                    top: 480
                },
                end: {
                    left: 503,
                    top: 537
                },
                href: 'https://play.google.com/store/apps/details?id=kr.co.goodpin.androidapp',
                target: '_blank'
            },
            {
                id: 'slide01',
                start: {
                    left: 520,
                    top: 480
                },
                end: {
                    left: 685,
                    top: 537
                },
                href: 'https://apps.apple.com/kr/app/guspin/id1146341201',
                target: '_blank'
            },
            {
                id: 'slide02',
                start: {
                    left: 1040,
                    top: 480
                },
                end: {
                    left: 1230,
                    top: 530
                },
                href: '/brand',
                target: ''
            },
            {
                id: 'slide03',
                start: {
                    left: 320,
                    top: 480
                },
                end: {
                    left: 510,
                    top: 540
                },
                href: '/patent',
                target: ''
            }
        ]
        this.imageVector = []
    }

    async componentDidMount() {
        $('html').removeClass('x-hidden')
        $('body').removeAttr('style').removeClass('x-hidden')
        this.imageVectorCalculate()
        this.imageVectorEvent()
        await this.mainBrands()
        AOS.init()
    }

    imageVectorEvent() {
        $(window).resize(() => {
            this.imageVectorCalculate()
        })
    }

    /**
     * Image map 좌표를 다시 계산한다
     * Comment by Gosomi
     * @date: 2021-09-09
     */
    imageVectorCalculate() {
        const ratio = $('#slide01').width() / 1920
        this.imageVector = _.map(this.imageVectorOrigin, (v) => {
            const cloneV = _.clone(v)
            cloneV.start = {
                left: v.start.left * ratio,
                top: v.start.top * ratio
            }
            cloneV.end = {
                left: v.end.left * ratio,
                top: v.end.top * ratio
            }
            return cloneV
        })
    }

    /**
     * 메인 브랜드 정보를 가져온다
     * Comment by Gosomi
     * @date: 2021-09-07
     */
    async mainBrands() {
        try {
            const response = await this.$resource.brands()
            this.companies = response.data
        } catch (e) {
            return this.$alert(e.message)
        }
    }

    /**
     * brand로 넘어가 바로 결제창을 띄운다
     * Comment by Gosomi
     * @date: 2021-09-18
     * @param companyId: string
     * @param type: number
     */
    goBrandDirectly(companyId: string, type: number) {
        this.$history.push({
            pathname: '/brand',
            state: {
                giftCompanyId: companyId,
                displayType: type
            }
        })
    }

    render() {
        return super.render()
    }
}