import * as React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { GoodpinComponent } from 'mixins/goodpin'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import _ from 'lodash'
import $ from 'jquery'

@inject('store')
@observer
export class FooterComponent extends GoodpinComponent {
    @observable isShowFooter: boolean
    @observable isShowFamily: boolean
    familySites
    bodyClickEvent = 'click.goodpin-footer'

    constructor(props) {
        super(props)
        this.isShowFooter = false
        this.isShowFamily = false
        this.familySites = [
            {
                name: '유디아이디',
                url: 'https://udid.co.kr'
            },
            {
                name: '페이앱',
                url: 'https://payapp.kr'
            },
            {
                name: '블로그페이',
                url: 'https://blogpay.co.kr'
            },
            {
                name: '프로셀',
                url: 'https://prosell.co.kr'
            },
            {
                name: '픽셀',
                url: 'https://picksell.co.kr/'
            },
            {
                name: '유캔사인',
                url: 'https://ucansign.com/'
            },
            {
                name: '메이크링크',
                url: 'https://make.link/'
            },
            {
                name: '부트페이',
                url: 'https://www.bootpay.co.kr'
            },
            {
                name: '스피드존',
                url: 'https://speedzone.co.kr/'
            },
            {
                name: '집사',
                url: 'https://zipsa.net'
            },
            {
                name: '단비페이',
                url: 'https://danbipay.com/main'
            },
            {
                name: '중고페이',
                url: 'https://junggopay.com/main'
            }
        ]
    }

    componentDidMount() {
        this.isShowFooter = !this.$present(this.getQuery('__accessToken')) || this.$session.isMemberAlive
    }

    showFamilySite() {
        $('body').unbind(this.bodyClickEvent)
        this.isShowFamily = !this.isShowFamily
        if (this.isShowFamily) {
            setTimeout(() => {
                $('body').unbind(this.bodyClickEvent).bind(this.bodyClickEvent, (e) => {
                    if ($(e.target).parents('#family-site').length === 0) {
                        $('body').unbind(this.bodyClickEvent)
                        this.isShowFamily = false
                    }
                })
            }, 200)
        }
    }

    render() {
        return this.isShowFooter && <footer>
            <div className="company">
                <div className="inner">
                    <div className="row">
                        <div className="col">
                            <div className="fmenu">
                                <a href="" onClick={ (e) => {
                                    e.preventDefault()
                                    return this.$showPolicy()
                                } }>이용약관</a>
                                <Link to="/patent">전자상품권판매 특허</Link>
                            </div>
                        </div>
                        <div className="col-auto text-right">
                            <div className="family-site-container">
                                <a className="family-site-btn" href="" onClick={ (e) => {
                                    e.preventDefault()
                                    return this.showFamilySite()
                                } }>
                                    Family Site
                                    <i className={ `mdi ${ this.isShowFamily ? 'mdi-chevron-up' : 'mdi-chevron-right' } ml-2` }></i>
                                </a>
                                {
                                    this.isShowFamily && <ul className="family-site-list" id="family-site">
                                        {
                                            _.map(this.familySites, (site) => {
                                                return <li key={ site.name }>
                                                    <a href={ site.url } target="_blank">{ site.name }</a>
                                                </li>
                                            })
                                        }
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                    <p>
                        <span>사업자등록번호 254-88-00338</span>
                        <span>통신판매신고업번호 제 2021-서울구로-2538호</span>
                        <span>대표자 정지광, 이용엽</span>
                        <span>서울특별시 구로구 디지털로26길 61, 12층 1202호(구로동, 에이스하이엔드타워 2차)</span>
                        <span>이메일 app.goodpin.co.kr@gmail.com</span>
                        <span>팩스 02-6918-6658</span>
                    </p>
                    <div className="copyright">Inspired by Charlie C.</div>
                </div>
            </div>
        </footer>
    }
}

export const Footer = withRouter(FooterComponent)