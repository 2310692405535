import React from 'react';

export default function() {
  return (
    <div className="goodpin-container certificate-index">
      <div className="goodpin-page-title">
        <div className="title-container">
          <div className="font-weight-bold">내상품권</div>
        </div>
      </div>
      <div className="mx-400 input-container">
        <div className="row m-0 p-0">
          <div className="col m-0 p-0">
            <input
              className="form-control"
              type="tel"
              pattern="[0-9]*"
              placeholder="휴대폰 번호 입력"
              value={this.phoneNumber}
              disabled={this.isRequestSms}
              onChange={e => {
                this.phoneNumber = e.currentTarget.value;
              }}
              onKeyDown={e => {
                return this.digitOnlyEvent(e);
              }}
            />
          </div>
          <div className="col-auto m-0 pl-2 pr-0">
            <button
              className="btn btn-outline-secondary btn-lg"
              onClick={e => {
                return this.requestSms();
              }}
            >
              인증번호받기
            </button>
          </div>
        </div>
        <div className="mt-2">
          <input
            className="form-control"
            type="tel"
            pattern="[0-9]*"
            placeholder="인증번호입력"
            disabled={!this.isRequestSms}
            value={this.code}
            ref={e => {
              this.codeInputRef = e;
            }}
            onChange={e => {
              this.code = e.currentTarget.value;
            }}
            onKeyDown={e => {
              return this.authCodeEvent();
            }}
          />
        </div>
        <button
          className="btn btn-danger btn-lg mt-3 btn-block mb-4"
          onClick={e => {
            this.publishToken();
          }}
        >
          확인
        </button>
        <p className="pt-4 comment border-top fs-2 text-gray">
          인증을 5회이상 실패하면 개인정보보호를 위해 24시간동안 휴대폰 인증을 받을 수 없습니다.
        </p>
      </div>
    </div>
  );
}
