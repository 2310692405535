import React from 'react'
import { inject, observer } from 'mobx-react'
import { PurchaseResource } from 'resources/purchase'
import { observable } from 'mobx'
import $ from 'jquery'
import { GoodpinComponent } from 'mixins/goodpin'

@inject('store')
@observer
export class GiftPurchase extends GoodpinComponent {
    @observable type
    @observable isShow: boolean
    @observable step: number
    @observable phoneNumber: string
    @observable agree: boolean
    @observable backUrl: string
    @observable isConfirmShow: boolean
    giftTypeId: string | undefined
    scrollY: number
    paymentUrl: string

    constructor(props) {
        super(props)
        this.giftTypeId    = undefined
        this.type          = {}
        this.step          = 1
        this.isShow        = false
        this.isConfirmShow = false
        this.agree         = false
        this.paymentUrl    = undefined
        this.setResource<PurchaseResource>(new PurchaseResource())
    }

    /**
     * Purchase Type Data를 가져온다
     * Comment by Gosomi
     * @date: 2021-09-11
     */
    async purchaseTypeData() {
        try {
            const response = await this.$resource.purchaseTypeData(this.giftTypeId)
            const { type } = response.data
            this.type      = type
            location.hash  = 'giftPopup'
        } catch (e) {
            return this.$alert(e.message)
        }
    }

    async showGiftPurchase(giftTypeId: string, backUrl: string = '/brand') {
        this.scrollY = $(window).scrollTop()
        $(window).off('hashchange.giftPurchase').on('hashchange.giftPurchase', () => {
            this.isShow = location.hash === '#giftPopup'
        })
        this.backUrl     = backUrl
        this.giftTypeId  = giftTypeId
        this.step        = 1
        this.phoneNumber = this.$session.isMemberAlive ? this.$session.currentMember.phone : ''
        $('body').css({
            'overflow': 'hidden'
        })
        return this.purchaseTypeData()
    }

    /**
     * 구매창 닫을 때 이벤트
     * Comment by Gosomi
     * @date: 2021-09-13
     */
    hideGiftPurchase() {
        $('body').removeAttr('style')
        location.hash = ''
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): Promise<void> {
        if (this.isShow) {
            $('body').css({
                overflow: 'hidden'
            })
        } else {
            $('body').removeAttr('style')
        }
        return super.componentDidUpdate(prevProps, prevState, snapshot)
    }

    /**
     * 상품권을 결제한다
     * Comment by Gosomi
     * @date: 2021-09-24
     */
    async giftPayment() {
        this.paymentUrl = undefined
        if (this.$blank(this.phoneNumber) || this.phoneNumber.length === 0) {
            return this.$alert('구매할 휴대폰 번호를 입력해주세요.')
        }

        if (!this.agree) {
            return this.$alert('이용약관에 동의해주세요.')
        }

        try {
            const response = await this.$resource.requestPayment({
                bp:           this.phoneNumber,
                rp:           this.phoneNumber,
                online:       1,
                safe:         1,
                step:         this.step,
                order:        [{
                    gift_type_id: this.type.gift_type_id,
                    qos:          1
                }],
                prepaid:      0,
                price:        this.type.price,
                request_type: this.$session.isMemberAlive ? 1 : 0
            })
            // 세션정보를 다시 가져온다
            await this.parentRef.setAccessToken()
            if (this.$present(response?.data?.receipt_url)) {
                this.isConfirmShow = true
                this.paymentUrl    = response.data.receipt_url
                // window.open(response.data.receipt_url, '_blank', "width=500px,height=700px,left=0,top=0,location=no,menubar=no,resizable=yes,status=no")
            } else {
                this.hideGiftPurchase()
                return this.$alert(`
                ${ this.phoneNumber } 번호로 결제 링크 요청을 보냈습니다. 확인해보시고 결제를 진행해주세요.<br/><br/><strong class='text-danger line-height-normal fs-2 font-weight-bold'>(* 어떠한 경우에도 결제링크를 타인에게 전송하지 마세요. 금전적인 피해가 발생됩니다.)</strong>
            `)
            }
        } catch (e) {
            return this.$alert(e.message)
        }
    }

    async startPayment() {
        this.isConfirmShow = false
        this.hideGiftPurchase()
        window.open(this.paymentUrl, '_blank', "width=500px,height=700px,left=0,top=0,location=no,menubar=no,resizable=yes,status=no")
    }

    render() {
        return this.isShow && <div
            className={ this.isConfirmShow ? "full-window bg-light-dark window-middle text-center online-purchase p-4" : "full-window bg-light-dark window-middle text-center online-purchase" }>
            { this.isConfirmShow && <div className="gift-purchase-window h-auto">
                <div className="body p-4">
                    <span className="font-weight-bold">{ this.type.name }</span>을 <span
                    className="font-weight-bold text-danger">{ this.type?.price?.toComma() }원</span>에 결제합니다.
                    &nbsp;결제 후 <span className="font-weight-bold">{ this.phoneNumber }</span> 번호로 구매하신 상품권을 SMS로 발송합니다.
                    <br/>
                    <br/>
                    진행하시려면 아래 <span className="font-weight-bold text-danger">"시작하기"</span>를 눌러주세요.
                </div>
                <div className="footer">
                    <a href="" className="bg-danger lh-60px fs-3 d-block text-center text-white font-weight-bold"
                       onClick={ (e) => {
                           e.preventDefault()
                           return this.startPayment()
                       } }>
                        결제 시작하기
                    </a>
                </div>
            </div>
            }
            { !this.isConfirmShow && <div className="gift-purchase-window">
                <div className="header text-center">
                    <a className="close-btn" href="" onClick={ (e) => {
                        e.preventDefault()
                        this.hideGiftPurchase()
                        this.$present(this.topRef) ? this.topRef.setScroll(this.scrollY) : undefined
                        return this.$history.push({
                            pathname: this.backUrl
                        })
                    } }>
                        <i className="mdi mdi-close fs-5 text-dark"/>
                    </a>
                    <img src={ this.type.image }/>
                </div>
                <div className="body">
                    <div className="content bg-white">
                        <h4>{ this.type.name }</h4>
                        <div className="price fs-4 font-weight-bold">
                            { this.type.price.toComma() }원
                        </div>
                        <div className="mt-3 p-3 border border-dark rounded-lg">
                            <div className="row">
                                <div className="col-auto">유효기간</div>
                                <div className="col text-right text-dark font-weight-bold">{ this.type.expire }일</div>
                            </div>
                        </div>
                    </div>
                    <div className="split-bar">
                    </div>
                    <div className="content bg-white">
                        <h5>주의사항</h5>
                        <div className="gift-content pt-3 pb-3"
                             dangerouslySetInnerHTML={ { __html: this.type.content.replace(/\n/gi, "<br/>") } }/>
                    </div>
                    <div className="split-bar">
                    </div>
                    <div className="content bg-white">
                        <h5>결제수단</h5>
                        <div className="mt-3">
                            { this.type.price >= 10000 ? <div className="row m-0 p-0">
                                <div className="col-12 m-0 p-0">
                                    <div className="pl-2 pr-2">
                                        <a className={ `payment pt-60p text-center ${ this.step === 0 ? 'active' : '' }` }
                                           href="" onClick={ (e) => {
                                            e.preventDefault()
                                            this.step = 0
                                        } }>
                                            <div className="d-inline-block align-middle">
                                                <img src={ this.getImagePath('icon/ico-pay-card.png') }/>
                                                <div className="name mt-1">신용카드</div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div> : <div className="row m-0 p-0">
                                <div className="col-6 m-0 p-0">
                                    <div className="pr-3">
                                        <a className={ `payment pt-60p text-center ${ this.step === 1 ? 'active' : '' }` }
                                           href="" onClick={ (e) => {
                                            e.preventDefault()
                                            this.step = 1
                                        } }>
                                            <div className="d-inline-block align-middle">
                                                <img src={ this.getImagePath('icon/ico-pay-phone.png') }/>
                                                <div className="name mt-1">휴대폰</div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-6 m-0 p-0">
                                    <div className="pl-2 pr-2">
                                        <a className={ `payment pt-60p text-center ${ this.step === 0 ? 'active' : '' }` }
                                           href="" onClick={ (e) => {
                                            e.preventDefault()
                                            this.step = 0
                                        } }>
                                            <div className="d-inline-block align-middle">
                                                <img src={ this.getImagePath('icon/ico-pay-card.png') }/>
                                                <div className="name mt-1">신용카드</div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            }
                            {/*<div className="col-4 m-0 p-0">*/ }
                            {/*	<div className="pl-3">*/ }
                            {/*		<a className="payment text-center" href="" onClick={(e) => {}}>*/ }
                            {/*			<div className="d-inline-block align-middle">*/ }
                            {/*				<img src={ this.getImagePath('icon/ico-pay-point.png') }/>*/ }
                            {/*				<div className="name mt-1">적립금</div>*/ }
                            {/*			</div>*/ }
                            {/*		</a>*/ }
                            {/*	</div>*/ }
                            {/*</div>*/ }
                        </div>
                    </div>
                    <div className="split-bar">
                    </div>
                    <div className="content bg-white">
                        <h5>휴대폰 번호</h5>
                        <input type="tel" className="form-control" value={ this.phoneNumber } onChange={ (e) => {
                            this.phoneNumber = e.currentTarget.value
                        } } disabled={ this.$session.isMemberAlive }/>
                    </div>
                    <div className="split-bar">
                    </div>
                    <div className="content bg-white">
                        <div className="row p-0 m-0 align-self-center align-items-center">
                            <div className="col-auto w-200px p-0 m-0">
                                <a className="d-block w-100" href="" onClick={ (e) => {
                                    e.preventDefault()
                                    this.agree = !this.agree
                                } }>
                                    <div className={ `checkbox ${ this.agree && 'checked' }` }>
                                        {
                                            this.agree && <i className="mdi mdi-check fs-3"/>
                                        }
                                    </div>
                                    <span className="ml-2">약관에 동의합니다</span>
                                </a>
                            </div>
                            <div className="col p-0 m-0 text-right">
                                <a className="text-danger border-bottom border-danger" href="" onClick={ (e) => {
                                    e.preventDefault()
                                    return this.$showPolicy()
                                } }>
                                    약관보기
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="" className="bg-danger lh-60px fs-3 d-block text-center text-white font-weight-bold"
                       onClick={ (e) => {
                           e.preventDefault()
                           return this.giftPayment()
                       } }>
                        결제하기
                    </a>
                </div>
            </div>
            }
        </div>
    }
}