import * as React from 'react'
import { ReactComponent } from 'support-js/react/component'

export class HowToList extends ReactComponent<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        return <div className="howto-list-index">
            <div className="tab-section">
                <h3 className="tab-title text-center">전체내역보기</h3>
                <div className="row howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label">
                            <i className="mdi mdi-file-document"/>
                        </label>
                        <h5 className="tab-sub-title">
                            이용내역보기
                        </h5>
                        <p className="tab-comment mt-4">
                            앱 하단 메뉴에서 '이용내역'을 클릭하여
                            <br/>
                            이용내역을 확인합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/list-app-1.jpg') } className="w-100"/>
                    </div>
                </div>
                <div className="row mt-5 howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label"><i className="mdi mdi-close-circle"/></label>
                        <h5 className="tab-sub-title">
                            취소내역 보기
                        </h5>
                        <p className="tab-comment mt-4">
                            이용내역 페이지에서
                            <br/>
                            앱 상단 '취소' 버튼을 클릭하여
                            <br/>
                            취소내역을 확인합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/list-app-2.jpg') } className="w-100"/>
                    </div>
                </div>
                <div className="row mt-5 howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label"><i className="mdi mdi-cube-send"/></label>
                        <h5 className="tab-sub-title">
                            선물 보낸 내역 보기
                        </h5>
                        <p className="tab-comment mt-4">
                            이용내역 페이지에서
                            <br/>
                            앱 상단 '보냄' 버튼을 클릭하여
                            <br/>
                            선물 보낸 내역을 확인합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/list-app-3.jpg') } className="w-100"/>
                    </div>
                </div>
                <div className="row mt-5 howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label"><i className="mdi mdi-gift-open"/></label>
                        <h5 className="tab-sub-title">
                            선물 받은 내역 보기
                        </h5>
                        <p className="tab-comment mt-4">
                            이용내역 페이지에서
                            <br/>
                            앱 상단 '받음' 버튼을 클릭하여
                            <br/>
                            선물 받은 내역을 확인합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/list-app-4.jpg') } className="w-100"/>
                    </div>
                </div>
            </div>
        </div>
    }
}