import * as React from 'react'
import { ReactComponent } from 'support-js/react/component'

export class HowToCancel extends ReactComponent<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        return <div className="howto-cancel-index">
            <div className="tab-section">
                <h3 className="tab-title text-center">결제 후 바로 취소</h3>
                <div className="row howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label"><i className="mdi mdi-close-circle"/></label>
                        <h5 className="tab-sub-title">
                            상품권 페이지에서
                            <br/>
                            구매취소 클릭
                        </h5>
                        <p className="tab-comment mt-4">
                            결제완료 후 수신된 문자의 URL을 클릭하여
                            <br/>
                            구매취소를 진행합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/cancel-pc-1.jpg') } className="w-100"/>
                    </div>
                </div>
            </div>
            <div className="tab-section">
                <h3 className="tab-title text-center">APP에서 취소</h3>
                <div className="row howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label"><i className="mdi mdi-close-circle"/></label>
                        <h5 className="tab-sub-title">
                            내 상품권 페이지에서
                            <br/>
                            구매취소 클릭
                        </h5>
                        <p className="tab-comment mt-4">
                            내 상품권 페이지에서 취소할 상품권을
                            <br/>
                            선택하여 구매취소를 진행합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/cancel-app-1.jpg') } className="w-100"/>
                    </div>
                </div>
            </div>
        </div>
    }
}