import { ReactResource } from 'support-js/react/resources'

export class CertificateResource extends ReactResource {
    constructor() {
        super()
    }

    /**
     * 구매한 상품권 리스트를 가져온다
     * Comment by Gosomi
     * @date: 2021-08-19
     * @param lastId: string

     */
    async getCertificate(lastId: string | undefined = undefined) {
        return this.semaphoreExecute('getCertificate', async () => {
            return this.$http.get(
                '/certificate',
                {
                    params: {
                        last_id: lastId
                    }
                }
            )
        })
    }
}