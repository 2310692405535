import React, { RefObject } from 'react'
import './style.sass'
import { isPresent } from 'support-js/support'

export interface BootProgressOption {
    timeout?: number
    ref?: BootProgress
}

export class BootProgress extends React.Component<any, any> {
    timeoutInstance
    option: BootProgressOption

    constructor(props) {
        super(props)
        this.state = {
            isShow: false,
            message: ''
        }
        this.timeoutInstance = undefined
        this.option = {
            timeout: 60
        }
    }

    template() {
        return {
            _html: this.state.message
        }
    }

    show(message: string, option: BootProgressOption = {}) {
        this.setState({
            isShow: true,
            message: message
        })
        this.option = option
        this.option.timeout = this.option.timeout || 60
        this.timeoutInstance = setTimeout(() => {
            this.setState({
                isShow: false
            })
        }, this.option.timeout * 1000)
    }

    hide() {
        this.setState({
            isShow: false,
            message: ''
        })
    }

    isProgress() {
        return this.state.isShow
    }

    render() {
        return this.state.isShow && (
            <div className="ng-progress-background">
                <div className="progress-message-window">
                    <div className="bootpay-loading">
                        <div className="bootpay-bar-loader">
                            <span/>
                            <span/>
                            <span/>
                            <span/>
                        </div>
                    </div>
                    <div className="bootpay-text" dangerouslySetInnerHTML={ { __html: this.state.message } }>
                    </div>
                </div>
            </div>
        )
    }
}

export const bootProgressRef: RefObject<BootProgress> = React.createRef()

export const showProgress = (message: string, option: BootProgressOption = {}) => {

    return isPresent(option.ref) ? option.ref.show(message) : (isPresent(bootProgressRef.current) ? bootProgressRef.current.show(message) : null)
}

export const hideProgress = (ref = undefined) => {
    return isPresent(ref) ? ref.hide() : (isPresent(bootProgressRef.current) ? bootProgressRef.current.hide() : null)
}

export const isProgress = () => {
    return isPresent(bootProgressRef.current) && bootProgressRef.current.isProgress()
}