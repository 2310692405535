import React from 'react';

export default function() {
  return (
    <main className="main-index goodpin-container" role="main">
      <section className="silde-image main-section" id="visual">
        <div className="inner mx-1280">
          <div className="txtbox">
            <div className="tit" data-aos="fade-down">
              {this.isOkGoodpinServiceTag && (
                <div className="title">
                  비즈 전용 모바일쿠폰
                  <br />
                  OK 굿핀
                </div>
              )}
              {!this.isOkGoodpinServiceTag && (
                <div className="title">
                  모든 상품권
                  <br />
                  바로 사서 바로 쓰세요
                </div>
              )}
            </div>
            <p className="font-weight-normal" data-pos="fade-left">
              {!this.isOkGoodpinServiceTag && (
                <span>
                  결제만 하면 바로 쓸 수 있습니다
                  <br />
                  PC/모바일 가리지 않고 어디서든 편리하게 사용해보세요.
                </span>
              )}
              {this.isOkGoodpinServiceTag && (
                <span>
                  모든 상품권을
                  <br />
                  바로 사서 바로 쓰세요
                </span>
              )}
            </p>
            <div className="btnbox" data-aos="fade-up">
              <a
                className="btn btn-white btn-google p-0"
                href="https://play.google.com/store/apps/details?id=kr.co.goodpin.androidapp"
                target="_blank"
                title="새창열림"
              ></a>
              <a
                className="btn btn-white btn-appstore p-0"
                href="https://itunes.apple.com/kr/app/guspin/id1146341201?mt=8"
                target="_blank"
                title="새창열림"
              ></a>
            </div>
          </div>
          <div className="imgbox">
            <div className="circle">
              <div className="circle1"></div>
              <div className="circle2"></div>
              <div className="circle3"></div>
              <div className="circle4"></div>
              <div className="circle5"></div>
            </div>
            <div className="mask"></div>
            <div className="card-wrap">
              <img src={this.getImagePath('page/img_parisbaguette.png')} alt="파리바게뜨 교환권" />
              <img src={this.getImagePath('page/img_starbucks.png')} alt="스타벅스 교환권" />
              <img src={this.getImagePath('page/img_baskin.png')} alt="베스킨라빈스 교환권" />
              <img src={this.getImagePath('page/img_twosome.png')} alt="투썸플레이스 교환권" />
              <img src={this.getImagePath('page/img_touslesjours.png')} alt="뚜레쥬르 교환권" />
              <img src={this.getImagePath('page/img_smoothieking.png')} alt="스무디킹 교환권" />
              <img src={this.getImagePath('page/img_ediya.png')} alt="이디야 교환권" />
              <img src={this.getImagePath('page/img_lotteria.png')} alt="롯데리아 교환권" />
              <img src={this.getImagePath('page/img_gongcha.png')} alt="공차 교환권" />
              <img src={this.getImagePath('page/img_juicy.png')} alt="쥬시 교환권" />
              <img src={this.getImagePath('page/img_cu.png')} alt="CU 교환권" />
              <img src={this.getImagePath('page/img_paikdabang.png')} alt="백다방 교환권" />
              <img src={this.getImagePath('page/img_dalkomm.png')} alt="달콤커피 교환권" />
              <img src={this.getImagePath('page/img_gs25.png')} alt="GS25 교환권" />
              <img src={this.getImagePath('page/img_paul_bassett.png')} alt="폴바셋 교환권" />
            </div>
            <div className="device" data-aos="fade-in">
              <img src={this.getImagePath('page/img_vis_device.png', true)} alt="굿핀앱 메인" />
            </div>
          </div>
        </div>
      </section>
      <div className="gift-container">
        <div className="mx-1280">
          <h3 className="title" data-aos="fade-down">
            모바일 상품권을 구매하세요
          </h3>
          <div className="main-brand-list">
            <div className="row">
              {(this.companies || []).map((company, i) => (
                <div className="col-md-3 col-lg-2 col-sm-3 col-6 brand-container" key={i} data-aos="fade-up">
                  <a
                    className="brand"
                    href=""
                    onClick={e => {
                      this.preventDefault(e, () => this.goBrandDirectly(company.gift_company_id, company.display_type));
                    }}
                  >
                    <img src={company.logo} alt={company.name} />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <section className="main-section" id="enjoy">
        <div className="inner">
          <div className="imgbox">
            <div className="mask"></div>
            <div className="google" data-aos="fade-right" data-aos-duration="300"></div>
            <div className="coffee" data-aos="fade-right" data-aos-duration="300"></div>
            <div className="device" data-aos="fade-down" data-aos-duration="300">
              <img src={this.getImagePath('page/img_enj_device.png')} alt="굿핀 앱 상품권 구매하기" />
            </div>
          </div>
          <div className="txtbox">
            <div className="tit" data-aos="fade-down">
              굿핀과 함께 하는
              <br />
              생활의 즐거움
            </div>
            <p data-aos="fade-left">
              이제 커피나 브런치 먹을때도,
              <br />
              현질이 필요 할때도 굿핀을 하세요.
            </p>
            <div className="btnbox" data-aos="fade-up">
              <a
                className="btn btn-gray btn-link btn-arrow p-0"
                href=""
                onClick={e => {
                  this.preventDefault(e, () => this.toPush('/brand'));
                }}
              >
                <em className="text-gray">구매하기</em>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="main-section" id="security">
        <div className="inner">
          <div className="txtbox">
            <div className="tit" data-aos="fade-down">
              핀 번호 노출 걱정 없이
              <br />
              안심하고 쓸 수 있어요
            </div>
            <p data-aos="fade-left">
              국내 최초 특허 신기술로 핀 번호가 노출되지 않아
              <br />
              안심하고 사용가능합니다.
            </p>
            <div className="btnbox" data-aos="fade-up">
              <a
                className="btn btn-gray btn-arrow btn-link p-0"
                href=""
                onClick={e => {
                  this.preventDefault(e, () => this.toPush('/patent'));
                }}
              >
                <em className="text-gray">자세히보기</em>
              </a>
            </div>
          </div>
          <div className="imgbox">
            <div className="browser" data-aos="fade-in"></div>
            <div className="lock" data-aos="zoom-in" data-aos-duration="600"></div>
            <div className="num" data-aos="zoom-in-up" data-aos-duration="300"></div>
          </div>
        </div>
      </section>
      <section className="main-section" id="payment">
        <div className="inner">
          <div className="imgbox">
            <div className="circle-card" data-aos="fade-left" data-aos-duration="300"></div>
            <div className="circle-phone" data-aos="fade-right" data-aos-duration="600"></div>
            <div className="device" data-aos="fade-in">
              <img src={this.getImagePath('page/img_pay_device.png', true)} alt="굿핀 앱 상품권결제하기" />
            </div>
            <div className="layer-gray" data-aos="fade-down-right"></div>
          </div>
          <div className="txtbox">
            <div className="tit" data-aos="fade-down">
              간편한 결제수단으로
              <br />
              바로 사용하세요
            </div>
            <p data-aos="fade-left">
              휴대폰 소액결제, 신용카드, 적립금 결제 등<br />
              다양한 결제방법으로 결제됩니다.
            </p>
            <div className="tag" data-aos="fade-up">
              <strong>#휴대폰결제</strong>
              <strong>#신용카드</strong>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="customer">
          <div className="inner">
            <strong>무엇이든 물어보세요</strong>
            <div>
              <a className="text-danger" href="tel:1855-2653">
                <span></span>1855-2653
              </a>
              <p>
                <span>평일</span>09:00 ~ 18:00<span>휴무</span>주말/공휴일
              </p>
            </div>
          </div>
        </div>
      </footer>
    </main>
  );
}
