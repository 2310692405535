import { PolicyRef } from 'components/policy'
import { ReactComponent } from 'support-js/react/component'

declare global {
    interface Window {
        Goodpin: any
    }
}

export class GoodpinComponent extends ReactComponent<any, any> {
    accessToken: string
    shortCategoryData

    constructor(props) {
        super(props)
        this.shortCategoryData = {
            '베이커리/도넛':      '베이커리',
            '식품/건강':        '식품/건강',
            "아이스크림/초콜릿/캔디": '아이스크림',
            '영화':           '영화',
            '온라인상품권':       '온라인상품권',
            '커피/음료':        '커피/음료',
            '패스트푸드/레스토랑':   '패스트푸드',
            '편의점':          '편의점',
            '피자/치킨':        '피자/치킨',
            '화장품/미용/향수':    '화장품'
        }
    }

    async setAccessToken() {
        if (this.$present(this.getQuery('__accessToken'))) {
            this.accessToken = this.getQuery('__accessToken')
            this.$resource.setDefaultHeader('Authorization', this.accessToken)
            try {
                const response       = await this.$session.getFetchSession()
                this.$session.member = response.data
            } catch (e) {
                e.success = false
                return this.$callApp(e)
            }
        }
    }

    /**
     * Short Category로 Convert
     * Comment by Gosomi
     * @date: 2021-10-05
     * @param category: string
     * @returns string
     */
    convertShortCategory(category: string): string {
        return this.$presence(this.shortCategoryData[category], category)
    }

    /**
     * 숫자만 받는다
     * Comment by Gosomi
     * @date: 2021-09-28
     */
    digitOnlyEvent(e) {
        const value = parseInt(e.key)
        if (/Digit/.test(e.code) || !isNaN(value)) {
            return true
        } else {
            if (['Backspace', 'Delete'].indexOf(e.code) === -1 && [8].indexOf(e.keyCode) === -1) {
                e.preventDefault()
                return false
            }
        }
    }

    /**
     * Location Hash Change
     * Comment by Gosomi
     * @date: 2021-10-05
     * @param hash: string
     * @param call: any
     */
    hashChange(hash: string) {
        location.hash = hash
    }

    /**
     * 약관 동의 창을 보기
     * Comment by Gosomi
     * @date: 2021-09-13
     */
    '$showPolicy'() {
        return this.$present(PolicyRef.current) ? PolicyRef.current.showPolicy() : undefined
    }

    '$showPrivatePolicy'() {
        try {
            // @ts-ignore
            Goodpin.postMessage('open_privacy')
        } catch (e) {
            window.open('https://tide-postage-243.notion.site/cb1e227f242e41219a809717e5dcfdd9', 'policy_popup', 'width=500,height=700')
        }
    }

    '$callApp'(data: {}) {
        try {
            window.Goodpin.postMessage(JSON.stringify(data))
        } catch (e) {
            // 아무것도 하지 않는다
        }
    }
}