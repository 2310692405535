import React from 'react';

export default function(__params = {}) {
  const { $, GiftPurchase, OnlinePurchase, TopBannerComponent, undefined, url } = __params;
  return (
    this.onLoad && (
      <div className="brand-app-index brand-index">
        <TopBannerComponent url={url}></TopBannerComponent>
        <section className="header">
          <a className="logo" href="">
            <img src={this.getImagePath('logo.png', true)} />
          </a>
          {this.searchMode && (
            <button
              className="left-btn"
              onClick={() => {
                return this.hashChange('');
              }}
            >
              <i className="mdi mdi-chevron-left fs-5"></i>
            </button>
          )}
          {!this.searchMode && (
            <button
              className="right-btn"
              onClick={() => {
                return this.hashChange('search');
              }}
            >
              <img src={this.getImagePath('icon/ico-search.svg')} />
            </button>
          )}
        </section>
        <section className="body">
          {this.searchMode && (
            <div className="app-search mt-3">
              <div className="input-control">
                <input
                  className="no-border"
                  ref={ref => (this.inputRef = ref)}
                  onChange={e => {
                    this.search.tag = e.currentTarget.value;
                  }}
                  onKeyUp={e => {
                    e.key === 'Enter' ? this.inputRef.blur() : undefined;
                    return e.key === 'Enter' ? this.reloadBrands(true, true) : true;
                  }}
                />
                <a
                  className="search-btn"
                  href=""
                  onClick={e => {
                    e.preventDefault();
                    this.inputRef.blur()``;
                    return this.reloadBrands(true, true);
                  }}
                >
                  <i className="mdi mdi-magnify fs-4"></i>
                </a>
              </div>
            </div>
          )}
          <div className="inner">
            {!this.searchMode && (
              <div className="brand-list">
                <h3 className="title mt-4 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0">브랜드별 보기</h3>
                <div className="row brands">
                  {(this.bestBrands || []).map((company, i) => (
                    <div className="col-4 brand-items" key={i}>
                      <a
                        className="item"
                        href=""
                        onClick={e => {
                          e.preventDefault();
                          return this.showPurchaseWindow(company);
                        }}
                      >
                        <div className="img text-center">
                          <img src={this.getImagePath(`logo/${company.namespace}.png`)} className={company.namespace} />
                        </div>
                      </a>
                    </div>
                  ))}
                  {(this.withoutBestBrandsCompanies || []).map((company, i) => (
                    <div className="col-4 brand-items" key={i}>
                      <a
                        className="item"
                        href=""
                        onClick={e => {
                          e.preventDefault();
                          return this.showPurchaseWindow(company);
                        }}
                      >
                        <div className={'img text-center ' + company.namespace}>
                          <img
                            src={this.getImagePath(`logo/${company.namespace}.png`)}
                            className={company.namespace}
                            style={{
                              display: `${this.imagePathExist(`logo/${company.namespace}.png`) ? 'block' : 'none'}`
                            }}
                          />
                          <span
                            className="text fs-2"
                            style={{
                              display: `${this.imagePathExist(`logo/${company.namespace}.png`) ? 'none' : 'inline'}`
                            }}
                          >
                            {company.name}
                          </span>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
                <button
                  className="btn btn-block btn-lg btn-secondary mt-3"
                  onClick={e => {
                    this.brandMore = !this.brandMore;
                  }}
                >
                  <span>{`${this.brandMore ? '감추기' : '더보기'}`}</span>
                </button>
              </div>
            )}
            {!this.searchMode && (
              <div className="mt-4 rounded-xl bg-white p-4 mb-4">
                <h3 className="title mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0">카테고리별 보기</h3>
                <div className="row categories">
                  {(this.categories || []).map((category, i) => (
                    <div className="col-3 category-items" key={i}>
                      <a
                        className="item"
                        href=""
                        onClick={e => {
                          e.preventDefault();
                          this.search.companyIds = [];
                          this.search.categories = [category];
                          return this.reloadBrands(true, true);
                        }}
                      >
                        <div className="img">
                          <img
                            className="w-100"
                            src={this.getImagePath(
                              `icon/category/${this.convertShortCategory(category).replace(/\//gi, '_')}.png`
                            )}
                          />
                        </div>
                      </a>
                      <div className="text text-center mt-2">{this.convertShortCategory(category)}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="main bg-white">
            <div className="right border-0" id="good-content">
              <div className="count d-block-d-sm-none d-md-none d-lg-none d-xl-none">
                {this.count.toComma()}개의 상품
                <div className="order">
                  <a
                    href=""
                    onClick={e => {
                      this.preventDefault(e, () => (this.isShowOrder = !this.isShowOrder));
                    }}
                  >
                    <span className="text fs-2_5">{this.search.order}</span>
                    <i className="mdi mdi-swap-vertical"></i>
                  </a>
                  {this.isShowOrder && (
                    <ul className="order-list">
                      <li>
                        <a
                          className={`${this.search.order === '인기순' ? 'active' : ''}`}
                          href=""
                          onClick={e => {
                            e.preventDefault();
                            this.search.order = '인기순';
                            this.isShowOrder = false;
                            return this.reloadBrands(true);
                          }}
                        >
                          인기순
                        </a>
                      </li>
                      <li>
                        <a
                          className={`${this.search.order === '이름순' ? 'active' : ''}`}
                          href=""
                          onClick={e => {
                            e.preventDefault();
                            this.search.order = '이름순';
                            this.isShowOrder = false;
                            return this.reloadBrands(true);
                          }}
                        >
                          이름순
                        </a>
                      </li>
                      <li>
                        <a
                          className={`${this.search.order === '높은가격순' ? 'active' : ''}`}
                          href=""
                          onClick={e => {
                            e.preventDefault();
                            this.search.order = '높은가격순';
                            this.isShowOrder = false;
                            return this.reloadBrands(true);
                          }}
                        >
                          높은가격순
                        </a>
                      </li>
                      <li>
                        <a
                          className={`${this.search.order === '낮은가격순' ? 'active' : ''}`}
                          href=""
                          onClick={e => {
                            e.preventDefault();
                            this.search.order = '낮은가격순';
                            this.isShowOrder = false;
                            return this.reloadBrands(true);
                          }}
                        >
                          낮은가격순
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              {this.types.length > 0 && (
                <div className="row">
                  {(this.types || []).map((type, i) => (
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3" key={i}>
                      {type.tag == 1 && (
                        <div className="item-block">
                          <a
                            className="item"
                            href=""
                            onClick={e => {
                              e.preventDefault();
                              return this.giftPurchaseRef.showGiftPurchase(type.gift_type_id, '/app/brand');
                            }}
                          >
                            <div className="image">
                              <img
                                src={type.image}
                                alt={type.name}
                                onError={e => {
                                  $(e.currentTarget).hide();
                                  $(e.currentTarget)
                                    .parent()
                                    .find('span')
                                    .show();
                                }}
                              />
                              <span className="text" style={{ display: 'none' }}>
                                {type.name}
                              </span>
                            </div>
                            <div className="info">
                              <div className="brand d-block d-sm-none d-md-none d-lg-none d-xl-none">
                                {this.companyDataByKey(type.gift_company_id, 'name', '회사명없음')}
                              </div>
                              <div className="item-name">
                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                  [{this.companyDataByKey(type.gift_company_id, 'name', '회사명없음')}]&nbsp;
                                </span>
                                <span>{type.dn}</span>
                              </div>
                              <div className="price">{type.price.toComma()}원</div>
                            </div>
                          </a>
                        </div>
                      )}
                      {type.tag == 0 && (
                        <div className="item-block">
                          <a
                            className="item"
                            href=""
                            onClick={e => {
                              e.preventDefault();
                              return this.onlinePurchaseRef.showOnlinePurchase(type.gift_company_id, '/app/brand');
                            }}
                          >
                            <div className="image online">
                              <img
                                src={type.logo}
                                alt={type.name}
                                onError={e => {
                                  $(e.currentTarget).hide();
                                  $(e.currentTarget)
                                    .parent()
                                    .find('span')
                                    .show();
                                }}
                              />
                              <span className="text" style={{ display: 'none' }}>
                                {type.name}
                              </span>
                            </div>
                            <div className="info">
                              <div className="brand d-block d-sm-none d-md-none d-lg-none d-xl-none">
                                {type.name} 상품권
                              </div>
                              <div className="item-name">
                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                  {type.name} 상품권
                                </span>
                              </div>
                              <div className="price">
                                {(type.types || []).map((t, i) => (
                                  <span className="fs-2" key={i}>
                                    {t.price > 5000 && <span className="mr-1">{t.name}</span>}
                                  </span>
                                ))}
                              </div>
                            </div>
                          </a>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {this.types.length === 0 && (
                <div className="p-5 text-center font-weight-bold">검색된 상품권이 없습니다.</div>
              )}
              {this.isShowGetMore && (
                <section className="main-section mt-3 mb-3">
                  <div className="btnbox">
                    <a
                      className="btn btn-white btn-link btn-arrow btn-block p-0 btn-lg border-0"
                      onClick={e => {
                        e.preventDefault();
                        return this.reloadBrands();
                      }}
                    >
                      <em className="text-gray">상품 더보기</em>
                    </a>
                  </div>
                </section>
              )}
            </div>
          </div>
        </section>
        <OnlinePurchase ref={ref => (this.onlinePurchaseRef = ref)} parentRef={this}></OnlinePurchase>
        <GiftPurchase ref={ref => (this.giftPurchaseRef = ref)} parentRef={this}></GiftPurchase>
      </div>
    )
  );
}
