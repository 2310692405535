import React from 'react';

export default function() {
  return (
    <div className="app-window full-window">
      <div className="pt-5 pl-3 pr-3 pb-4 bg-white">
        <div className="d-inline-block w-100 rounded-xl shadow-lg p-3">
          <div className="row align-items-center">
            <div className="col">
              <img className="w-40px" src={this.getImagePath('icon/user.png')} />
              {this.$session.isMemberAlive && <span className="text ml-2">{this.$session.currentMember.phone}</span>}
            </div>
            <div className="col-auto">
              <a
                className="text-danger fs-2"
                href=""
                onClick={e => {
                  e.preventDefault();
                  return this.appLogout();
                }}
              >
                <span className="mr-1">로그아웃</span>
                <i className="mdi mdi-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="title">고객센터</div>
      <ul className="preference-list bg-white">
        <li>
          <button
            onClick={() => {
              this.$history.push(
                `/post/notice?__accessToken=` + this.getQuery('__accessToken') + '&ver=' + this.getQuery('ver')
              );
            }}
          >
            <div className="row align-items-center">
              <div className="col">공지사항</div>
              <div className="col-auto">
                <i className="mdi mdi-chevron-right fs-4"></i>
              </div>
            </div>
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              this.$history.push(
                `/post/faq?__accessToken=` + this.getQuery('__accessToken') + '&ver=' + this.getQuery('ver')
              );
            }}
          >
            <div className="row align-items-center">
              <div className="col">자주 묻는 질문</div>
              <div className="col-auto">
                <i className="mdi mdi-chevron-right fs-4"></i>
              </div>
            </div>
          </button>
        </li>
      </ul>
      <div className="title">약관 및 정보</div>
      <ul className="preference-list bg-white">
        <li>
          <button
            onClick={() => {
              this.$showPolicy();
            }}
          >
            <div className="row align-items-center">
              <div className="col">이용 약관</div>
              <div className="col-auto">
                <i className="mdi mdi-chevron-right fs-4"></i>
              </div>
            </div>
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              this.$showPrivatePolicy();
            }}
          >
            <div className="row align-items-center">
              <div className="col">개인정보처리 방침</div>
              <div className="col-auto">
                <i className="mdi mdi-chevron-right fs-4"></i>
              </div>
            </div>
          </button>
        </li>
        <li>
          <button>
            <div className="row align-items-center">
              <div className="col">앱 버전 정보</div>
              <div className="col-auto">
                <span className="text-danger fs-3">v{this.version}</span>
              </div>
            </div>
          </button>
        </li>
      </ul>
      <div className="title">개인정보</div>
      <ul className="preference-list bg-white">
        <li>
          <button
            onClick={() => {
              this.drawPrivatePolicy();
            }}
          >
            <div className="row align-items-center">
              <div className="col">개인정보 삭제</div>
              <div className="col-auto">
                <i className="mdi mdi-chevron-right fs-4"></i>
              </div>
            </div>
          </button>
        </li>
      </ul>
    </div>
  );
}
