import React from 'react';

export default function() {
  return (
    <div className="goodpin-container certificate-index">
      <div className="goodpin-page-title">
        <div className="title-container">
          <div className="font-weight-bold">내상품권</div>
        </div>
      </div>
      <div className="mx-1280 input-container">
        <div className="certificate-container">
          <div className="comment border-bottom fs-2 pb-1 text-gray mb-0">최근 10건만 표시됩니다.</div>
        </div>
        {this.certificates.length > 0 && (
          <div className="certificate-list-container">
            {(this.certificates || []).map((certificate, i) => (
              <div className="certificate-list mt-3" key={i}>
                {certificate.company_data.display_type === 1 && (
                  <div className="row pl-0 pr-0 pb-3 m-0 align-items-center border-bottom" key={i}>
                    <div className="col-auto w-100px text-center p-0 m-0">
                      <div className="thumb-box">
                        <img
                          className="w-100"
                          src={this.getImagePath(`logo/${certificate.company_data.namespace}.png`)}
                        />
                      </div>
                    </div>
                    <div className="col pl-2 pr-0 m-0 position-relative">
                      <div className="d-none d-sm-block d-lg-block d-md-block d-xl-block">
                        <div className="datetime text-gray fs-2">{certificate.created_at}</div>
                        <div className="certificate-name mt-1">
                          <span>{certificate.company_data.name} 상품권</span>
                          <span className="price font-weight-bold ml-2">{certificate.price.toComma()}</span>
                        </div>
                        <a className="right-button" href={certificate.url} target="_blank">
                          상품권 {certificate.count}장보기 &nbsp;<i className="mdi mdi-chevron-right"></i>
                        </a>
                      </div>
                      <div className="d-block d-sm-none d-lg-none d-md-none d-xl-none">
                        <a className="d-block" href={certificate.url} target="_blank">
                          <div className="datetime text-gray fs-2">{certificate.created_at}</div>
                          <div className="certificate-name mt-1">
                            <span>{certificate.company_data.name} 상품권</span>
                            <span className="price font-weight-bold ml-2">{certificate.price.toComma()}</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {certificate.company_data.display_type === 2 && (
                  <div className="row pl-0 pr-0 pb-3 m-0 align-items-center border-bottom" key={i}>
                    <div className="col-auto w-100px text-center p-0 m-0">
                      <div className="thumb-box">
                        <img className="w-100" src={certificate.type_data.image} />
                      </div>
                    </div>
                    <div className="col pl-2 pr-0 m-0 position-relative">
                      <div className="d-none d-sm-block d-lg-block d-md-block d-xl-block">
                        <div className="datetime text-gray fs-2">{certificate.created_at}</div>
                        <div className="certificate-name mt-1">
                          <span>
                            [ {certificate.company_data.name} ] {certificate.type_data.name}
                          </span>
                          <span className="price font-weight-bold ml-2">{certificate.price.toComma()}</span>
                        </div>
                        <a className="right-button" href={certificate.url} target="_blank">
                          기프티콘 보기 &nbsp;<i className="mdi mdi-chevron-right"></i>
                        </a>
                      </div>
                      <div className="d-block d-sm-none d-lg-none d-md-none d-xl-none">
                        <a className="d-block" href={certificate.url} target="_blank">
                          <div className="datetime text-gray fs-2">{certificate.created_at}</div>
                          <div className="certificate-name mt-1">
                            <span>
                              [ {certificate.company_data.name} ] {certificate.type_data.name}
                            </span>
                            <span className="price font-weight-bold ml-2">{certificate.price.toComma()}</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {this.certificates.length === 0 && (
          <div className="certificate-list pt-3 pb-3 text-center font-weight-bold">보유중인 상품권이 없습니다.</div>
        )}
      </div>
    </div>
  );
}
