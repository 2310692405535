import { ReactResource } from 'support-js/react/resources'

export class TokenResource extends ReactResource {
    constructor() {
        super()
    }

    /**
     * token을 가져오기 위한 SMS를 요청한다
     * Comment by Gosomi
     * @date: 2021-08-19
     * @param phoneNumber: string
     */
    async requestSms(phoneNumber: string) {
        return this.semaphoreExecute('requestSms', async () => {
            return this.$http.post(
                '/request/sms',
                {
                    phone: phoneNumber
                }
            )
        })
    }

    /**
     * 접근 가능한 token을 발행 받는다
     * Comment by Gosomi
     * @date: 2021-08-19
     * @param phoneNumber: string
     * @param code: string
     */
    async publishToken(phoneNumber: string, code: string) {
        return this.semaphoreExecute('publishToken', async () => {
            return this.$http.put(
                `/request/sms/${ phoneNumber }`,
                {
                    code: code
                }
            )
        })
    }
}