import "core-js/stable"
import "regenerator-runtime/runtime"
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'support-js/support/override'
import { App } from 'components/app'
import * as React from 'react'
import ReactDom from 'react-dom'
import 'bootstrap/dist/js/bootstrap.bundle'
import '@mdi/font/scss/materialdesignicons.scss'
import 'entrypoints/application.sass'
import 'stylesheets/application.sass'
import { TemplateView } from 'stores/template-view'
import { configure } from 'mobx'
// @ts-ignore
import { Constant } from 'stores/const.ts.erb'
import { ChannelInstance } from 'entrypoints/channel'

configure({
    enforceActions: "never",
    computedRequiresReaction: false,
    reactionRequiresObservable: true,
    observableRequiresReaction: false,
    disableErrorBoundaries: false
})

document.addEventListener('DOMContentLoaded', (event: Event) => {
    ReactDom.render((
            <App view={ TemplateView }/>
        )
        , document.getElementById('goodpin')
    )
})