import { generateUUID, isBlank } from 'support-js/support'

const DeviceStorage = {
    DEVICE_KEY: '__BOOTPAY_D__',
    localStorage: {},
    getDeviceId() {
        let deviceId = this.getDeviceIdByStorage()
        if (isBlank(deviceId)) {
            deviceId = this.updateDeviceId()
        }
        return deviceId
    },
    getDeviceIdByStorage() {
        try {
            return window.localStorage.getItem(this.DEVICE_KEY)
        } catch (e) {
            return this.localStorage[this.DEVICE_KEY]
        }
    },

    updateDeviceId() {
        const deviceId = this.generateDeviceId()
        try {
            window.localStorage.setItem(this.DEVICE_KEY, deviceId)
        } catch (e) {
            this.localStorage[this.DEVICE_KEY] = deviceId
        }
        return deviceId
    },
    generateDeviceId() {
        return `${generateUUID()}-${(new Date()).getTime()}`
    }
}

export { DeviceStorage }