import React from 'react'
import { ReactComponent } from 'support-js/react/component'

export class HowToMobile extends ReactComponent<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        return <div className="howto-mobile-index">
            <div className="tab-section">
                <h3 className="tab-title text-center">APP화면</h3>
                <div className="row howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label">1</label>
                        <h5 className="tab-sub-title">
                            앱에서 구매할
                            <br/>
                            모바일 쿠폰 선택
                        </h5>
                        <p className="tab-comment mt-4">
                            앱 메인에서 구매하고자 하는
                            <br/>
                            모바일 쿠폰을 선택합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/mobile-app-1.jpg') } className="w-100"/>
                    </div>
                </div>
                <div className="row mt-5 howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label">2</label>
                        <h5 className="tab-sub-title">
                            결제수단 선택

                        </h5>
                        <p className="tab-comment mt-4">
                            결제수단 선택 후
                            <br/>
                            결제하기를 진행합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/mobile-app-2.jpg') } className="w-100"/>
                    </div>
                </div>
                <div className="row mt-5 howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label">3</label>
                        <h5 className="tab-sub-title">
                            SMS로 받은 링크 클릭 후
                            <br/>
                            안내에 따라 결제
                        </h5>
                        <p className="tab-comment mt-4">
                            SMS로 받은 결제링크를 클릭합니다.
                            <br/>
                            안내에 따라 결제창에서 결제를 완료합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/mobile-app-3.jpg') } className="w-100"/>
                    </div>
                </div>
            </div>
            <div className="tab-section">
                <h3 className="tab-title text-center">PC 화면</h3>
                <div className="row howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label">1</label>
                        <h5 className="tab-sub-title">
                            사이트에서 구매할
                            <br/>
                            상품 브랜드 선택
                        </h5>
                        <p className="tab-comment mt-4">
                            사이트 상단에서 '상품권 구매하기'를 클릭하여
                            <br/>
                            구매하고자 하는 상품 브랜드를 선택합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/mobile-pc-1.jpg') } className="w-100"/>
                    </div>
                </div>
                <div className="row mt-5 howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label">2</label>
                        <h5 className="tab-sub-title">
                            구매할 모바일 쿠폰 선택 후
                            <br/>
                            결제수단 선택
                        </h5>
                        <p className="tab-comment mt-4">
                            리스트에서 구매할 모바일 쿠폰 선택 후
                            <br/>
                            결제수단을 선택하여 결제를 진행합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/mobile-pc-2.jpg') } className="w-100"/>
                    </div>
                </div>
                <div className="row mt-5 howto-container">
                    <div className="col-auto w-300px">
                        <label className="tab-label">3</label>
                        <h5 className="tab-sub-title">
                            SMS로 받은 링크 클릭 후
                            <br/>
                            안내에 따라 결제
                        </h5>
                        <p className="tab-comment mt-4">
                            SMS로 받은 결제링크를 클릭합니다.
                            <br/>
                            안내에 따라 결제창에서 결제를 완료합니다.
                        </p>
                    </div>
                    <div className="col">
                        <img src={ this.getImagePath('howto/mobile-pc-3.jpg') } className="w-100"/>
                    </div>
                </div>
            </div>
        </div>
    }
}