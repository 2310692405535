import React from 'react'
import { inject, observer } from 'mobx-react'
import { GoodpinComponent } from 'mixins/goodpin'
import { BrandResource } from 'resources/brand'
import { computed, observable } from 'mobx'
import { OnlinePurchase } from 'components/brand/online-purchase'
import { GiftPurchase } from 'components/brand/gift-purchase'
import { TopBannerComponent } from 'components/top-banner'
import _ from 'lodash'
import $ from 'jquery'

@inject('store')
@observer
export class AppBrand extends GoodpinComponent {
    @observable onLoad: boolean
    @observable bestBrands
    @observable companies
    @observable categories
    @observable brandMore: boolean
    @observable count: number
    @observable page: number
    @observable types
    @observable search
    @observable isShowGetMore: boolean
    @observable isShowOrder: boolean
    @observable searchMode: boolean
    onlinePurchaseRef
    giftPurchaseRef
    scrollY: number
    inputRef

    constructor(props) {
        super(props)
        this.$template = require('app/brand')
        this.setResource<BrandResource>(new BrandResource())
        this.onLoad        = false
        this.bestBrands    = []
        this.companies     = []
        this.categories    = []
        this.types         = []
        this.count         = 0
        this.page          = 1
        this.brandMore     = false
        this.isShowGetMore = true
        this.isShowOrder   = false
        this.searchMode    = false
        this.search        = {
            tag:        '',
            categories: [],
            companyIds: [],
            order:      '인기순'
        }
        this.scrollY       = 0
    }

    /**
     * 페이지에서 돌아온 이후 스크롤 조정
     * Comment by Gosomi
     * @date: 2021-10-06
     * @param scrollY: number
     */
    setScroll(scrollY: number) {
        this.scrollY = scrollY
    }

    async componentDidMount() {
        location.hash = ''
        $('html').addClass('x-hidden')
        $('body').addClass('x-hidden')
        this.onLoad = true
        await this.setAccessToken()
        this.basicBrand()
        return this.reloadBrands(true)
    }

    componentHashChange(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        super.componentHashChange(prevProps, prevState, snapshot)
        if (this.scrollY > 0) {
            $(window).scrollTop(this.scrollY)
            this.scrollY = 0
        }
        this.searchMode = location.hash === '#search'
        if (this.searchMode) {
            this.$nextTick(() => {
                this.inputRef.focus()
            })
        }
    }

    @computed
    get withoutBestBrandsCompanies() {
        const companyIds = _.map(this.bestBrands, (v) => v.gift_company_id)
        return this.brandMore ? _.filter(this.companies, (v) => companyIds.indexOf(v.gift_company_id) === -1) : []
    }

    /**
     * 회사 정보를 리턴
     * Comment by Gosomi
     * @date: 2021-09-09
     */
    companyById(companyId: string) {
        const company = _.filter(this.companies, (v) => v.gift_company_id === companyId)
        return this.$present(company) ? company.first() : undefined
    }

    /**
     * 회사 정보중 일부 정보를 노출
     * Comment by Gosomi
     * @date: 2021-09-09
     */
    companyDataByKey(companyId: string, key: string, defaultValue: any = '') {
        return this.$present(this.companyById(companyId)) ? this.$presence(this.companyById(companyId)[key], defaultValue) : defaultValue
    }

    /**
     * 기본 정보를 로드한다
     * Comment by Gosomi
     * @date: 2021-09-09
     */
    async basicBrand() {
        try {
            const response                               = await this.$resource.basicBrand()
            const { best_brands, companies, categories } = response.data
            this.bestBrands                              = best_brands
            this.companies                               = companies
            this.categories                              = categories
        } catch (e) {
            return this.$alert(e.message)
        }
    }

    /**
     * 브랜드 정보를 갱신한다
     * Comment by Gosomi
     * @date: 2021-09-07
     */
    async reloadBrands(refresh = false, searchFocus = false) {
        if (refresh) {
            this.types = []
            this.page  = 1
        } else {
            this.page += 1
        }
        if (/문화|해피|틴캐시|구글|도서|퍼니|티캐시|컬쳐/.test(this.search.tag) || this.search.categories.indexOf('온라인상품권') > -1) {
            this.onLoad = true
            this.preFilterOnlineGift()
        } else {
            try {
                const response        = await this.$resource.brands(
                    this.search.tag,
                    this.search.categories,
                    this.search.companyIds,
                    this.search.order,
                    this.page
                )
                this.onLoad           = true
                const { list, count } = response.data
                if (this.$blank(this.types) || this.types.length === 0) {
                    this.count = count
                }
                this.types         = this.types.concat(list)
                this.isShowGetMore = (this.count !== 0 && this.types.length) && this.count > this.types.length
            } catch (e) {
                this.onLoad = true
                if (e.code !== 10) {
                    return this.$alert(e.message)
                }
            }
        }
        if (refresh && searchFocus) {
            const goodContentOffsetY = $('#good-content').offset().top + $('body').scrollTop()
            setTimeout(() => {
                $('body').scrollTop(goodContentOffsetY - 40)
            }, 50)
        }
    }


    /**
     * 검색어 PreFilter 기능
     * Comment by Gosomi
     * @date: 2021-09-27
     */
    preFilterOnlineGift() {
        if (this.search.categories.indexOf('온라인상품권') > -1 && this.$blank(this.search.tag)) {
            this.types = _.filter(this.bestBrands, (v) => {
                v.tag = 0
                return v.display_type === 1
            })
        } else {
            this.types = _.filter(this.bestBrands, (v) => {
                v.tag = 0
                return (new RegExp(`${ this.search.tag }`)).test(v.name)
            })
        }
        this.count         = this.types.length
        this.isShowGetMore = false
    }

    // 결제창을 보여주거나 검색창으로 넘긴다
    showPurchaseWindow(brand) {
        // 핀코드 발급 방식
        if (brand.display_type === 1) {
            this.onlinePurchaseRef.showOnlinePurchase(brand.gift_company_id, '/app/brand')
        } else {
            this.search.tag        = ''
            this.search.categories = []
            this.search.companyIds = [brand.gift_company_id]
            return this.reloadBrands(true, true)
        }
    }

    render() {
        return super.render({
            $,
            OnlinePurchase,
            GiftPurchase,
            TopBannerComponent,
            url: `app/brand?__accessToken=${ this.accessToken }`
        })
    }
}