import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { computed, observable } from 'mobx'
import { NoticeResource } from 'resources/notice'
import _ from 'lodash'
import { GoodpinComponent } from 'mixins/goodpin'

@inject('store')
@observer
export class Post extends GoodpinComponent {
    @observable noticeId: string
    @observable noticeLists: Array<any>
    @observable showNoticeId: string
    @observable noticeSearch: string
    noticeIndexes = ['notice', 'faq']
    noticeNames   = [
        '공지사항',
        '자주묻는질문'
    ]

    constructor(props) {
        super(props)
        this.noticeLists  = []
        this.noticeId     = props.match.params.id
        this.showNoticeId = undefined
        this.noticeSearch = ''
        this.setResource(new NoticeResource())
    }

    async getNoticeList(isMore = true) {
        if (!isMore) {
            this.noticeLists = []
        }
        try {
            const response = await this.$resource.getNoticeList(this.noticeId, isMore ? this.lastNoticeId : undefined, this.noticeSearch)
            if (isMore && this.$blank(response.data)) {
                return this.$alert('더이상 가져올 게시물이 없습니다.')
            } else if (!isMore && this.$blank(response.data)) {
                return
            } else {
                this.noticeLists = this.noticeLists.concat(response.data)
                this.forceUpdate()
            }
        } catch (e) {
            return e.code === 10 ? undefined : this.$alert(e.message)
        }
    }


    async componentHistoryChange(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): Promise<void> {
        this.noticeId = this.props.match.params.id
        await this.getNoticeList(false)
    }

    @computed
    get lastNoticeId() {
        if (this.$present(this.noticeLists) && this.noticeLists.length > 0) {
            return this.noticeLists.last().notice_id
        } else {
            return undefined
        }
    }

    /**
     * Notice index를 가져온다
     * Comment by Gosomi
     * @date: 2021-08-18
     * @returns number
     */
    @computed
    get getNoticeIndex() {
        return this.noticeIndexes.indexOf(this.noticeId)
    }

    /**
     * POST 이름을 가져온다
     * Comment by Gosomi
     * @date: 2021-08-18
     * @returns string
     */
    @computed
    get noticeTitleName() {
        return this.noticeNames[this.getNoticeIndex]
    }

    async componentDidMount() {
        await this.setAccessToken()
        await this.getNoticeList(false)
    }

    render() {
        return <div className="notice-index goodpin-container">
            {
                !this.$session.isMemberAlive && <div className="goodpin-page-title">
                    <div className="title-container">
                        <div className="font-weight-bold">{ this.noticeTitleName }</div>
                    </div>
                </div>
            }
            {
                this.$session.isMemberAlive && <section className="app-header">
                    <span className="header-text">{ this.noticeTitleName }</span>
                    <a className="left-btn" onClick={ (e) => {
                        e.preventDefault()
                        return this.$history.push('/app/preference?__accessToken=' + this.getQuery('__accessToken') + "&ver=" + this.getQuery('ver'))
                    } }>
                        <i className="mdi mdi-chevron-left fs-4"/>
                    </a>
                </section>
            }
            <div className="content-container">
                <div className="mx-1280 mobile-no-padding">
                    {
                        !this.$session.isMemberAlive && <div className="search-container">
                            <div className="search-group">
                                <input type="text" className="search-control" placeholder="검색어를 입력하세요."
                                       value={ this.noticeSearch }
                                       onChange={ (e) => {
                                           this.noticeSearch = e.currentTarget.value
                                       } }
                                       onKeyUp={ async (e) => {
                                           if (e.key === 'Enter') {
                                               await this.getNoticeList(false)
                                           }
                                       } }
                                />
                                <button className="search-magnify" onClick={ async (e) => {
                                    await this.getNoticeList(false)
                                } }><i className="mdi mdi-magnify"/></button>
                            </div>
                        </div>
                    }
                    <div className={ `notice-container ${ this.$session.isMemberAlive ? 'mt-2' : 'mt-5' }` }>
                        {
                            this.noticeLists.length > 0 && _.map(this.noticeLists, (notice, index) => {
                                return <div className={ `notice-row ${ index % 2 == 0 ? 'odd' : '' }` }
                                            key={ notice.notice_id }>
                                    <a href="" className="notice-title position-relative" onClick={ (e) => {
                                        e.preventDefault()
                                        this.showNoticeId = this.showNoticeId === notice.notice_id ? undefined : notice.notice_id
                                    } }>
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h6>{ notice.title }</h6>
                                                <p className="datetime text-gray fs-2 mb-0 mt-2">{ notice.created_at }</p>
                                            </div>
                                            <div className="col-auto">
                                                <div className="right-arrow">
                                                    <i className={ `mdi ${ this.showNoticeId === notice.notice_id ? 'mdi-chevron-down' : 'mdi-chevron-right' }` }/>
                                                </div>
                                            </div>
                                        </div>

                                    </a>
                                    {
                                        this.showNoticeId === notice.notice_id && <div className="notice-content"
                                                                                       dangerouslySetInnerHTML={ { __html: notice.content } }>
                                        </div>
                                    }
                                </div>
                            })
                        }
                        {
                            this.noticeLists.length === 0 && <div className="notice-row">
                                <div className="notice-title position-relative text-center">
                                    <h6>검색된 게시물이 없습니다.</h6>
                                </div>
                            </div>
                        }
                        {
                            this.noticeLists.length > 0 && <button className="more-btn" onClick={ async (e) => {
                                await this.getNoticeList()
                            } }>
                                더보기&nbsp;
                                <i className="mdi mdi-chevron-down"/>
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}