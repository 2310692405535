import * as React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { TokenResource } from 'resources/token'
import { CertificateResource } from 'resources/certificate'
import _ from 'lodash'
import { GoodpinComponent } from 'mixins/goodpin'

@observer
export class Certificate extends GoodpinComponent {
    @observable phoneNumber: string
    @observable isRequestSms: boolean
    @observable code: string
    @observable token: string | undefined
    @observable certificates: Array<any>
    @observable isCertificateListMode: boolean
    $tokenResource: TokenResource
    codeInputRef: HTMLInputElement | undefined

    constructor(props) {
        super(props)
        this.phoneNumber = ''
        this.isRequestSms = false
        this.$tokenResource = new TokenResource()
        this.token = undefined
        this.code = ''
        this.codeInputRef = undefined
        this.isCertificateListMode = false
        this.setResource<CertificateResource>(new CertificateResource())
        this.certificates = []
    }

    /**
     * 전화번호 입력 후 SMS 요청을 합니다
     * Comment by Gosomi
     * @date: 2021-08-19
     */
    async requestSms() {
        if (this.$blank(this.phoneNumber)) {
            return this.$alert('전화번호를 입력해주세요.')
        }
        try {
            const response = await this.$tokenResource.requestSms(this.phoneNumber)
            this.isRequestSms = true
            if (this.$present(response.data)) {
                this.code = response.data.code
            }
            await this.$alert(`${ this.phoneNumber } 번호로 SMS 6자리가 요청되었습니다. 수신된 번호를 입력 해주세요.`)
            this.codeInputRef.focus()
        } catch (e) {
            return this.$alert(e.message)
        }
    }

    /**
     * 접근이 가능한 토큰을 가져온다
     * Comment by Gosomi
     * @date: 2021-08-19
     */
    async publishToken() {
        if (this.$blank(this.phoneNumber)) {
            return this.$alert('전화번호를 입력해주세요.')
        }
        if (this.$blank(this.code) || String(this.code).length !== 6) {
            return this.$alert('문자로 수신한 인증번호 6자리를 입력해주세요.')
        }
        try {
            const response = await this.$tokenResource.publishToken(this.phoneNumber, this.code)
            this.token = response.data.token
            this.$resource.setDefaultHeader('Authorization', this.token)
            return this.getCertificate()
        } catch (e) {
            return this.$alert(e.message)
        }
    }

    /**
     * 보유중인 상품권 정보를 가져온다
     * Comment by Gosomi
     * @date: 2021-08-19
     */
    async getCertificate() {
        try {
            const response = await this.$resource.getCertificate()
            this.certificates = response.data
            this.isCertificateListMode = true
        } catch (e) {
            return this.$alert(e.message)
        }
    }

    authCodeEvent(e) {
        const value = parseInt(e.key)
        if (/Digit/.test(e.code) || !isNaN(value)) {
            if (e.currentTarget.value.length > 5) {
                e.preventDefault()
                return false
            } else {
                return true
            }
        } else {
            if (['Backspace', 'Delete'].indexOf(e.code) === -1) {
                e.preventDefault()
                return false
            }
        }
    }

    render() {
        return (this.isCertificateListMode ? require('certificate/list') : require('certificate')).default.call(this)
    }

    old_render() {
        return <div className="goodpin-container certificate-index">
            <div className="goodpin-page-title">
                <div className="title-container">
                    <div className="font-weight-bold">내상품권</div>
                </div>
            </div>
            {
                this.$blank(this.token) && <div className="mx-400 input-container">
                    <div className="row m-0 p-0">
                        <div className="col m-0 p-0">
                            <input type="tel" className="form-control" placeholder="핸드폰번호입력"
                                   value={ this.phoneNumber }
                                   disabled={ this.isRequestSms }
                                   onChange={ (e) => {
                                       this.phoneNumber = e.currentTarget.value
                                   } }
                                   onKeyDown={ (e) => {
                                       if (/Digit/.test(e.code)) {
                                           return true
                                       } else {
                                           if (['Backspace', 'Delete'].indexOf(e.code) === -1) {
                                               e.preventDefault()
                                               return false
                                           }
                                       }
                                   } }/>
                        </div>
                        <div className="col-auto m-0 pl-2 pr-0">
                            <button className="btn btn-outline-secondary btn-lg" onClick={ () => {
                                return this.requestSms()
                            } }>인증번호 받기
                            </button>
                        </div>
                    </div>
                    <div className="mt-2">
                        <input type="tel" className="form-control" placeholder="인증번호 입력"
                               disabled={ !this.isRequestSms }
                               value={ this.code }
                               ref={ (e) => {
                                   this.codeInputRef = e
                               } }
                               onChange={ (e) => {
                                   this.code = e.currentTarget.value
                               } }
                               onKeyDown={ (e) => {
                                   if (/Digit/.test(e.code)) {
                                       if (e.currentTarget.value.length > 5) {
                                           e.preventDefault()
                                           return false
                                       } else {
                                           return true
                                       }
                                   } else {
                                       if (['Backspace', 'Delete'].indexOf(e.code) === -1) {
                                           e.preventDefault()
                                           return false
                                       }
                                   }
                               } }/>
                    </div>
                    <button className="btn btn-danger btn-lg mt-3 btn-block mb-4" onClick={ () => this.publishToken() }>
                        확인
                    </button>
                    <p className="pt-4 comment border-top fs-2 text-gray">
                        인증을 5회이상 실패하면 개인정보보호를 위해 24시간동안 휴대폰 인증을 받을 수 없습니다.
                    </p>
                </div>
            }
            {
                this.$present(this.token) && <div className="mx-1280 input-container">
                    <div className="certificate-container">
                        <p className="comment border-bottom fs-2 pb-1 text-gray mb-0">최근 10건만 표시됩니다.</p>
                    </div>
                    {
                        this.certificates.length > 0 ? _.map(this.certificates, (certificate, index) => {
                            return <div className="certificate-list mt-3" key={ index }>
                                <div className="row pl-0 pr-0 pb-3 m-0 align-items-center border-bottom">
                                    <div className="col-auto w-100px text-center p-0 m-0">
                                        <div className="thumb-box">
                                            <img
                                                src={ this.getImagePath(`logo/${ certificate.company_data.namespace }.png`) }
                                                className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col pl-2 pr-0 m-0 position-relative">
                                        <div className="datetime text-gray fs-2">{ certificate.created_at }</div>
                                        <div className="certificate-name mt-1">
                                            <span>{ certificate.company_data.name }상품권</span>
                                            <span
                                                className="price font-weight-bold ml-2">{ certificate.price.toComma() }</span>
                                            <span className="unit">원</span>
                                        </div>
                                        <a className="right-button" href={ certificate.url } target="_blank">
                                            상품권 { certificate.count }장 보기&nbsp;
                                            <i className="mdi mdi-chevron-right"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }) : <div className="certificate-list pt-3 pb-3 text-center font-weight-bold">보유중인 상품권이
                            없습니다.</div>
                    }
                </div>
            }
        </div>
    }
}