import { ReactResource } from 'support-js/react/resources'

export class MainResource extends ReactResource {
    constructor() {
        super()
    }

    /**
     * 메인 페이지 브랜드 정보 로드
     * Comment by Gosomi
     * @date: 2021-09-07
     */
    async brands() {
        return this.semaphoreExecute('brands', async () => {
            return this.$http.get('/')
        })
    }
}