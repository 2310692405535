import React from 'react';

export default function(__params = {}) {
  const { $, GiftPurchase, OnlinePurchase } = __params;
  return (
    this.onLoad && (
      <div className="brand-index goodpin-container">
        <div className="goodpin-page-title d-none d-sm-block d-md-block d-lg-block d-xl-block"></div>
        <div className="split-bar d-block d-sm-none d-md-none d-lg-none d-xl-none"></div>
        <section className="header mx-1280">
          <h3 className="title mt-4 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0">
            {this.isOkGoodpinServiceTag && <span>OK굿핀에서</span>}
            {!this.isOkGoodpinServiceTag && <span>굿핀에서</span>}
            <span>&nbsp;가장 많이 찾아요.</span>
          </h3>
        </section>
        <section className="header mx-1280 p-0 position-relative">
          <div
            className="card-wrap row flex-nowrap flex-sm-wrap flex-md-wrap flex-lg-wrap flex-xl-wrap p-0 m-0 position-relative"
            onScroll={e => {
              this.showBestGuide = e.currentTarget.scrollLeft === 0;
            }}
          >
            {this.showBestGuide && (
              <div className="middle-text fs-5 text-gray d-block d-sm-none d-md-none d-lg-none d-xl-none">
                <i className="mdi mdi-chevron-double-right"></i>
                <span className="arrow-right-move"></span>
              </div>
            )}
            {(this.bestBrands || []).map((brand, i) => (
              <div className="col-6 col-md-4 col-lg-2 hot-card p-0 m-0" key={i}>
                <a
                  href=""
                  onClick={e => {
                    e.preventDefault();
                    this.showPurchaseWindow(brand);
                  }}
                >
                  <img src={this.getImagePath('logo/logo_bg.png')} />
                  <div className="logo">
                    <img src={this.getImagePath(`logo/${brand.namespace}.png`)} className={brand.namespace} />
                  </div>
                </a>
              </div>
            ))}
          </div>
        </section>
        <section className="header mx-1280 mobile-no-padding">
          <div className="search">
            <div className="input-control">
              <input
                className="no-border"
                value={this.search.tag}
                onChange={e => {
                  this.search.tag = e.currentTarget.value;
                }}
                onKeyUp={e => {
                  return e.key === 'Enter' ? this.reloadBrands(true, true) : true;
                }}
              />
              <a
                className="search-btn"
                href=""
                onClick={e => {
                  this.preventDefault(e, () => this.reloadBrands(true, true));
                }}
              >
                <i className="mdi mdi-magnify"></i>
              </a>
            </div>
          </div>
        </section>
        <section className="body mx-1280 mobile-no-padding">
          <div className="row main pt-3 pb-3">
            <div className="col-12 col-lg-auto col-sm-auto col-xl-auto col-md-auto left">
              <div className="split-bar d-block d-sm-none d-md-none d-lg-none d-xl-none"></div>
              <div className="left-side mt-3 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0">
                <h5>
                  <a
                    href=""
                    onClick={e => {
                      this.preventDefault(e, () => (this.isSearchCategories = !this.isSearchCategories));
                    }}
                  >
                    <span className="text">카테고리</span>
                    <i
                      className={'mdi ' + `mdi ${this.isSearchCategories ? 'mdi-chevron-up' : 'mdi-chevron-right'}`}
                    ></i>
                  </a>
                </h5>
                {this.isSearchCategories && (
                  <ul className="left-side-list">
                    <li
                      className={`${this.search.categories.length === 0 ? 'active' : ''}`}
                      onClick={e => {
                        e.preventDefault();
                        this.search.categories = [];
                        return this.reloadBrands(true);
                      }}
                    >
                      전체
                    </li>
                    {(this.categories || []).map((cat, i) => (
                      <li key={cat} className={`${this.search.categories.indexOf(cat) > -1 ? 'active' : ''}`}>
                        <a
                          href=""
                          onClick={e => {
                            e.preventDefault();
                            this.search.companyIds = [];
                            this.search.categories = [cat];
                            return this.reloadBrands(true);
                          }}
                        >
                          {cat}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="split-bar d-block d-sm-none d-md-none d-lg-none d-xl-none"></div>
              <div className="left-side mt-3">
                <h5>
                  <a
                    href=""
                    onClick={e => {
                      this.preventDefault(e, () => (this.isSearchBrand = !this.isSearchBrand));
                    }}
                  >
                    <span className="text">브랜드</span>
                    <i className={'mdi ' + `mdi ${this.isSearchBrand ? 'mdi-chevron-up' : 'mdi-chevron-right'}`}></i>
                  </a>
                </h5>
                {this.isSearchBrand && (
                  <div
                    className="row p-0 brand-list flex-nowrap flex-sm-wrap flex-md-wrap flex-lg-wrap flex-xl-wrap position-relative"
                    onScroll={e => {
                      this.showBrandGuide = e.currentTarget.scrollLeft === 0;
                    }}
                  >
                    {this.showBrandGuide && (
                      <div className="middle-text fs-5 text-gray d-block d-sm-none d-md-none d-lg-none d-xl-none">
                        <i className="mdi mdi-chevron-double-right"></i>
                        <span className="arrow-right-move"></span>
                      </div>
                    )}
                    {(this.companies || []).map((company, i) => (
                      <div className="col-4 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-0 m-0" key={i}>
                        <div className="brand-item">
                          <a
                            href=""
                            className={`${
                              this.search.companyIds.indexOf(company.gift_company_id) > -1 ? 'active' : ''
                            }`}
                            onClick={e => {
                              e.preventDefault();
                              this.search.companyIds =
                                this.search.companyIds.indexOf(company.gift_company_id) > -1
                                  ? []
                                  : [company.gift_company_id];
                              this.search.categories = [];
                              return this.reloadBrands(true);
                            }}
                          >
                            <div className="brand">
                              <img
                                src={company.logo}
                                onError={e => {
                                  $(e.currentTarget).hide();
                                  $(e.currentTarget)
                                    .parent()
                                    .find('span')
                                    .show();
                                }}
                              />
                              <span className="text" style={{ display: 'none' }}>
                                {company.name}
                              </span>
                            </div>
                          </a>
                        </div>
                      </div>
                    ))}
                    <div className="col-6 p-0 m-0"></div>
                  </div>
                )}
              </div>
            </div>
            <div className="col right" id="good-content">
              <div className="split-bar d-block d-sm-none d-md-none d-lg-none d-xl-none"></div>
              <div className="count d-block-d-sm-none d-md-none d-lg-none d-xl-none">
                {this.count.toComma()}개의 상품
                <div className="order">
                  <a
                    href=""
                    onClick={e => {
                      this.preventDefault(e, () => (this.isShowOrder = !this.isShowOrder));
                    }}
                  >
                    <span className="text fs-2_5">{this.search.order}</span>
                    <i className="mdi mdi-swap-vertical"></i>
                  </a>
                  {this.isShowOrder && (
                    <ul className="order-list">
                      <li>
                        <a
                          className={`${this.search.order === '인기순' ? 'active' : ''}`}
                          href=""
                          onClick={e => {
                            e.preventDefault();
                            this.search.order = '인기순';
                            this.isShowOrder = false;
                            return this.reloadBrands(true);
                          }}
                        >
                          인기순
                        </a>
                      </li>
                      <li>
                        <a
                          className={`${this.search.order === '이름순' ? 'active' : ''}`}
                          href=""
                          onClick={e => {
                            e.preventDefault();
                            this.search.order = '이름순';
                            this.isShowOrder = false;
                            return this.reloadBrands(true);
                          }}
                        >
                          이름순
                        </a>
                      </li>
                      <li>
                        <a
                          className={`${this.search.order === '높은가격순' ? 'active' : ''}`}
                          href=""
                          onClick={e => {
                            e.preventDefault();
                            this.search.order = '높은가격순';
                            this.isShowOrder = false;
                            return this.reloadBrands(true);
                          }}
                        >
                          높은가격순
                        </a>
                      </li>
                      <li>
                        <a
                          className={`${this.search.order === '낮은가격순' ? 'active' : ''}`}
                          href=""
                          onClick={e => {
                            e.preventDefault();
                            this.search.order = '낮은가격순';
                            this.isShowOrder = false;
                            return this.reloadBrands(true);
                          }}
                        >
                          낮은가격순
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              {this.types.length > 0 && (
                <div className="row">
                  {(this.types || []).map((type, i) => (
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3" key={i}>
                      {type.tag == 1 && (
                        <div className="item-block">
                          <a
                            className="item"
                            href=""
                            onClick={e => {
                              e.preventDefault();
                              return this.giftPurchase.showGiftPurchase(type.gift_type_id);
                            }}
                          >
                            <div className="image">
                              <img
                                src={type.image}
                                alt={type.name}
                                onError={e => {
                                  $(e.currentTarget).hide();
                                  $(e.currentTarget)
                                    .parent()
                                    .find('span')
                                    .show();
                                }}
                              />
                              <span className="text" style={{ display: 'none' }}>
                                {type.name}
                              </span>
                            </div>
                            <div className="info">
                              <div className="brand d-block d-sm-none d-md-none d-lg-none d-xl-none">
                                {this.companyDataByKey(type.gift_company_id, 'name', '회사명없음')}
                              </div>
                              <div className="item-name">
                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                  [{this.companyDataByKey(type.gift_company_id, 'name', '회사명없음')}]&nbsp;
                                </span>
                                <span>{type.dn}</span>
                              </div>
                              <div className="price">{type.price.toComma()}원</div>
                            </div>
                          </a>
                        </div>
                      )}
                      {type.tag == 0 && (
                        <div className="item-block">
                          <a
                            className="item"
                            href=""
                            onClick={e => {
                              e.preventDefault();
                              return this.onlinePurchase.showOnlinePurchase(type.gift_company_id);
                            }}
                          >
                            <div className="image online">
                              <img
                                src={type.logo}
                                alt={type.name}
                                onError={e => {
                                  $(e.currentTarget).hide();
                                  $(e.currentTarget)
                                    .parent()
                                    .find('span')
                                    .show();
                                }}
                              />
                              <span className="text" style={{ display: 'none' }}>
                                {type.name}
                              </span>
                            </div>
                            <div className="info">
                              <div className="brand d-block d-sm-none d-md-none d-lg-none d-xl-none">
                                {type.name} 상품권
                              </div>
                              <div className="item-name">
                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                  {type.name} 상품권
                                </span>
                              </div>
                              <div className="price">
                                {(type.types || []).map((t, i) => (
                                  <span className="fs-2" key={i}>
                                    {t.price > 5000 && <span className="mr-1">{t.name}</span>}
                                  </span>
                                ))}
                              </div>
                            </div>
                          </a>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {this.types.length === 0 && (
                <div className="p-5 text-center font-weight-bold">검색된 상품권이 없습니다.</div>
              )}
              {this.isShowGetMore && (
                <section className="main-section mt-3 mb-3">
                  <div className="btnbox">
                    <a
                      className="btn btn-white btn-link btn-arrow btn-block p-0 btn-lg border-0"
                      onClick={e => {
                        e.preventDefault();
                        return this.reloadBrands();
                      }}
                    >
                      <em className="text-gray">상품 더보기</em>
                    </a>
                  </div>
                </section>
              )}
            </div>
          </div>
        </section>
        <footer className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
          <div className="customer">
            <div className="inner">
              <strong>무엇이든 물어보세요</strong>
              <div className="information">
                <a className="text-danger" href="tel:1855-2653">
                  <span></span>1855-2653
                </a>
                <p>
                  <span>평일</span>
                  <span>09:00 ~ 18:00</span>
                  <span>휴무</span>
                  <span>주말/공휴일</span>
                </p>
              </div>
            </div>
          </div>
        </footer>
        <OnlinePurchase
          ref={ref => {
            this.onlinePurchase = ref;
          }}
          parentRef={this}
        ></OnlinePurchase>
        <GiftPurchase
          ref={ref => {
            this.giftPurchase = ref;
          }}
          parentRef={this}
        ></GiftPurchase>
      </div>
    )
  );
}
