import React, { RefObject } from 'react'
import 'support-js/react/components/boot-alert/style.sass'
import { isPresent } from 'support-js/support'

export class BootAlert extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.state = {
            isShow: false,
            title: '' || '확인',
            message: '',
            cancelText: '' || '아니오',
            confirmText: '' || '네',
            confirm: false,
            resolve: undefined,
            reject: undefined
        }
    }

    template() {
        return {
            __html: this.state.message
        }
    }

    show(message: string, title: string = '알림') {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                document.getElementById('boot-alert-success-button').focus()
            }, 200)
            this.setState({
                isShow: true,
                message: message,
                title: title,
                resolve: resolve,
                reject: reject,
                confirmText: '확인',
                confirm: false
            })
        })
    }

    confirm(message: string, title: string = '확인') {
        return new Promise((resolve, reject) => {
            this.setState({
                isShow: true,
                message: message,
                title: title,
                resolve: resolve,
                reject: reject,
                confirm: true
            })
        })
    }

    hide() {
        this.setState({
            isShow: false
        })
    }

    doCancel(e) {
        e.preventDefault()
        this.hide()
        this.state.reject()
    }

    doSuccess(e) {
        e.preventDefault()
        this.hide()
        this.state.resolve()
    }

    render() {
        return this.state.isShow && (
            <div className="boot-alert">
                <div className="alert-inner animate__animated animate__fadeIn animate__faster">
                    <div className="body" dangerouslySetInnerHTML={ this.template() }/>
                    <div className="footer">
                        { this.state.confirm ?
                            <div className="button-if layout-row">
                                <a href="" className="alert-button cancel-btn half-alert-button"
                                   onClick={ (e) => this.doCancel(e) }>{ this.state.cancelText }</a>
                                <a href="" id='boot-alert-success-button'
                                   className='alert-button success-btn half-alert-button bootpay-color bootpay-theme-color'
                                   onClick={ (e) => this.doSuccess(e) }>{ this.state.confirmText }</a>
                            </div> :
                            <div className='button-if w-100'>
                                <a href="" id='boot-alert-success-button'
                                   className='alert-button success-btn w-100 bootpay-color bootpay-theme-color'
                                   onClick={ (e) => this.doSuccess(e) }>{ this.state.confirmText }</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export const bootAlertRef: RefObject<BootAlert> = React.createRef()

export const showBootAlert = (message: string, title: string = '알림') => {
    return isPresent(bootAlertRef.current) ? bootAlertRef.current.show(message, title) : null
}

export const showBootConfirm = (message: string, title: string = '확인') => {
    return isPresent(bootAlertRef.current) ? bootAlertRef.current.confirm(message, title) : null
}