declare global {
    interface Window {
        ChannelIO: any
        ChannelIOInitialized: any
        attachEvent: any
    }
}

class ChannelService {
    constructor() {
        this.loadScript()
    }

    start(setting) {
        setTimeout(() => {
            window.ChannelIO('boot', setting)
        }, 1000)
    }

    loadScript() {
        const w = window
        if (window.ChannelIO) {
            return
        }
        const ch = () => {
            // @ts-ignore
            ch.c(arguments)
        }

        ch.q = []
        ch.c = (args) => {
            ch.q.push(args)
        }

        window.ChannelIO = ch
        const l = () => {
            if (w.ChannelIOInitialized) {
                return
            }
            w.ChannelIOInitialized = true
            let s = document.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js'
            s.charset = 'UTF-8'
            let x = document.getElementsByTagName('script')[0]
            x.parentNode.insertBefore(s, x)
        }


        if (document.readyState === 'complete') {
            l()
        } else if (window.attachEvent) {
            window.attachEvent('onload', l);
        } else {
            window.addEventListener('DOMContentLoaded', l, false)
            window.addEventListener('load', l, false)
        }
    }
}

export const ChannelInstance = new ChannelService()