import React from 'react'
import { ReactComponent } from 'support-js/react/component'
import 'stylesheets/maintenance'
import { MaintenanceResource } from 'resources/maintenance'
import { observer } from 'mobx-react'
import { observable } from 'mobx'

@observer
export class Maintenance extends ReactComponent<any, any> {
    @observable message

    constructor(props) {
        super(props)
        this.setResource<MaintenanceResource>(new MaintenanceResource())
        this.message = ''
    }

    async componentDidMount() {
        return this.maintenanceMessage()
    }

    async maintenanceMessage() {
        try {
            const response = await this.$resource.message()
            const { message } = response.data
            this.message = message
        } catch (e) {
            await this.$alert(e.message)
            return location.href = '/'
        }
    }

    render() {
        return <div className="maintenance-index">
            <div className="maintenance-content">
                <div className="header">
                    <div className="maintenance-icon">
                        <i className="mdi mdi-cog ion-spin gear-1"/>
                        <i className="mdi mdi-cog ion-spin gear-2 text-primary"/>
                        <i className="mdi mdi-cog ion-spin gear-3 text-danger"/>
                    </div>
                    <h2 className="site-title">서비스 점검중입니다</h2>
                </div>
                <div className="body">
                    <p className="comment" dangerouslySetInnerHTML={ { __html: this.message } }>
                    </p>
                </div>
                <div className="footer">
                    <h4 className="site-title">
                        (주)굿핀
                    </h4>
                </div>
            </div>
        </div>
    }
}