import { inject, observer } from 'mobx-react'
import { ReactComponent } from 'support-js/react/component'
import * as React from 'react'
import { observable } from 'mobx'

@inject('store')
@observer
export class TopBannerComponent extends ReactComponent<any, any> {
    @observable url: string

    constructor(props) {
        super(props)
        this.url = props.url === undefined ? 'brand' : props.url
    }

    render() {
        return <div className="header-top-banner">
            {
                this.isOkGoodpinServiceTag && <div className="mx-1280 text-center mobile-no-padding">
                    <div className="coin1">
                        <img src={ this.getImagePath('ok/banner/coin1.svg') }/>
                    </div>
                    <div className="coin2">
                        <img src={ this.getImagePath('ok/banner/coin2.svg') }/>
                    </div>
                    <a className="to-goodpin-service"
                       href={ `https://www.goodpin.co.kr/${ this.url }` }>
                        <img className="banner" src={ this.getImagePath('ok/banner/to-goodpin-banner.svg') }/>
                    </a>
                </div>
            }
            {
                !this.isOkGoodpinServiceTag &&
                <div className="mx-1280 text-center mobile-no-padding">
                    <a className="to-ok-goodpin-service no-border"
                       href={ `https://ok.goodpin.co.kr/${ this.url }` }>
                        <img className="banner" src={ this.getImagePath('ok-goodpin-banner.svg') }/>
                    </a>
                    <a className="to-ok-goodpin-service"
                       href={ `https://ok.goodpin.co.kr/${ this.url }` }>
                        OK 굿핀
                        <i className="mdi mdi-chevron-right"/>
                    </a>
                </div>
            }
        </div>
    }
}