import React from 'react'
import { ReactComponent } from 'support-js/react/component'
import { inject, observer } from 'mobx-react'
import { computed, observable } from 'mobx'
import { PurchaseResource } from 'resources/purchase'
import _ from 'lodash'
import $ from 'jquery'
import { GoodpinComponent } from 'mixins/goodpin'

@inject('store')
@observer
export class OnlinePurchase extends GoodpinComponent {
    @observable isShow
    @observable company
    @observable qty
    @observable backUrl: string
    giftCompanyId: string | undefined
    @observable prepaid
    @observable step
    @observable phoneNumber: string
    @observable agree: boolean
    scrollY: number

    constructor(props) {
        super(props)
        this.isShow        = false
        this.giftCompanyId = undefined
        this.setResource<PurchaseResource>(new PurchaseResource())
        this.qty         = []
        this.prepaid     = true
        this.step        = 1
        this.phoneNumber = ''
        this.agree       = false
        this.scrollY     = 0
    }

    componentDidMount() {
    }

    /**
     * 결제시 필요한 정보를 가져온다
     * Comment by Gosomi
     * @date: 2021-09-08
     */
    async purchaseData() {
        try {
            const response    = await this.$resource.purchaseCompanyData(this.giftCompanyId)
            const { company } = response.data
            this.company      = company
            this.qty          = _.map(this.company.types, (type) => {
                return { gift_type_id: type.gift_type_id, qos: 0 }
            })
            if (!this.isPinCodePublish) {
                this.hideOnlinePurchase()
                return this.$alert('핀코드 발급 방식의 상품권이 아닙니다.')
            }
            location.hash = 'onlinePopup'
        } catch (e) {
            this.hideOnlinePurchase()
            return this.$alert(e.message)
        }
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): Promise<void> {
        if (this.isShow) {
            $('body').css({
                overflow: 'hidden'
            })
        } else {
            $('body').removeAttr('style')
        }
        return super.componentDidUpdate(prevProps, prevState, snapshot)
    }

    /**
     * Pincode 발권 방식
     * Comment by Gosomi
     * @date: 2021-09-08
     * @returns boolean
     */
    @computed
    get isPinCodePublish() {
        return this.company.display_type === 1
    }

    /**
     * 현재 선택된 결제 수단의 Purchase 데이터
     * Comment by Gosomi
     * @date: 2021-09-08
     */
    @computed
    get currentPurchaseData() {
        switch (this.step) {
            case 0:
                return this.company.apr.card
            case 1:
                return this.company.apr.phone
            default:
                return undefined
        }
    }

    /**
     * 서비스 이용료 비율
     * Comment by Gosomi
     * @date: 2021-09-08
     */
    @computed
    get currentServicePaymentRatio() {
        if (this.$present(this.currentPurchaseData)) {
            return Math.floor(parseFloat((this.currentPurchaseData.value * 100 * 1.1).toFixed(2)) * 100) / 100
        } else {
            return 0
        }
    }

    /**
     * 현재 서비스 이용료
     * Comment by Gosomi
     * @date: 2021-09-08
     * @returns number
     */
    @computed
    get currentServicePayment(): number {
        if (this.$present(this.currentPurchaseData)) {
            // 선불로 낸다고 선택했고, 선불 요금이 가능한 결제 수단인 경우
            if (this.currentPurchaseData.pp === 1 && this.prepaid) {
                return Math.ceil(this.currentServicePaymentRatio * this.totalCertificatePrice / 10000) * 100
            } else {
                return 0
            }
        } else {
            return 0
        }
    }

    /**
     * OnlinePurchase 창을 보여준다
     * Comment by Gosomi
     * @date: 2021-09-08
     * @param giftCompanyId: string
     * @param backUrl: string
     * @returns void
     */
    async showOnlinePurchase(giftCompanyId: string, backUrl: string = '/brand') {
        this.scrollY = $(window).scrollTop()
        $(window).off('hashchange.onlinePurchase').on('hashchange.onlinePurchase', () => {
            this.isShow = location.hash === '#onlinePopup'
        })
        this.giftCompanyId = giftCompanyId
        this.qty           = []
        this.prepaid       = true
        this.step          = 1
        this.agree         = false
        this.backUrl       = backUrl
        this.phoneNumber   = this.$session.isMemberAlive ? this.$session.currentMember.phone : ''
        $('body').css({
            overflow: 'hidden'
        })
        return this.purchaseData()
    }

    /**
     * 상품권 정보 리턴
     * Comment by Gosomi
     * @date: 2021-09-08
     * @param typeId: string
     */
    typeData(typeId: string) {
        return _.filter(this.company.types, (type) => type.gift_type_id === typeId).first()
    }

    /**
     * Qty 데이터를 가져온다
     * Comment by Gosomi
     * @date: 2021-09-08
     * @param typeId: string
     */
    qtyDataType(typeId: string) {
        try {
            return _.filter(this.qty, (v) => v.gift_type_id === typeId).first()
        } catch {
            return undefined
        }
    }

    /**
     * 재고수
     * Comment by Gosomi
     * @date: 2021-09-08
     * @param typeId: string
     * @returns number
     */
    qtyByType(typeId: string) {
        try {
            return this.qtyDataType(typeId).qos
        } catch {
            return 0
        }
    }

    /**
     * 총 상품권 금액
     * Comment by Gosomi
     * @date: 2021-09-08
     */
    @computed
    get totalCertificatePrice() {
        return _.sumBy(this.qty, (v) => {
            return this.typeData(v.gift_type_id).price * v.qos
        })
    }

    /**
     * 총금액
     * Comment by Gosomi
     * @date: 2021-09-08
     */
    @computed
    get totalPrice() {
        return this.totalCertificatePrice + this.currentServicePayment
    }

    /**
     * 재고 수를 변경한다
     * Comment by Gosomi
     * @date: 2021-09-08
     * @param typeId: string
     * @param count: number
     */
    adjustQty(typeId: string, count: number) {
        if (this.qtyDataType(typeId).qos === 0 && count < 0) {
            return
        } else {
            this.qtyDataType(typeId).qos += count
            this.$nextTick(() => {
                // 휴대폰 소액 결제인 경우
                if (this.step === 1) {
                    // 금액이 오버 되는 경우
                    if (this.company.phone_limit[1] < this.totalPrice) {
                        this.qtyDataType(typeId).qos -= count
                        return this.$alert(`휴대폰 소액결제 최대 ${ this.company.phone_limit[1].toComma() }원까지 가능합니다.`)
                    }
                }
                // 카드 결제인 경우
                if (this.step === 0) {
                    // 금액이 오버 되는 경우
                    if (this.company.card_limit[1] < this.totalPrice) {
                        this.qtyDataType(typeId).qos -= count
                        return this.$alert(`신용카드 결제는 최대 ${ this.company.card_limit[1].toComma() }원까지 가능합니다.`)
                    }
                }
            })
        }
    }

    async certificatePayment() {
        if (this.$blank(this.phoneNumber) || this.phoneNumber.length === 0) {
            return this.$alert('구매할 휴대폰 번호를 입력해주세요.')
        }

        switch (this.step) {
            case 1:
                if (this.company.phone_limit[1] < this.totalPrice) {
                    return this.$alert(`휴대폰 소액결제 최대 ${ this.company.phone_limit[1].toComma() }원까지 가능합니다.`)
                }
                if (this.company.phone_limit[0] > this.totalPrice) {
                    return this.$alert(`휴대폰 소액결제 최소 ${ this.company.phone_limit[0].toComma() }원부터 가능합니다.`)
                }
                break
            case 0:
                if (this.company.card_limit[1] < this.totalPrice) {
                    return this.$alert(`신용카드 결제는 최대 ${ this.company.card_limit[1].toComma() }원까지 가능합니다.`)
                }
                if (this.company.card_limit[0] > this.totalPrice) {
                    return this.$alert(`신용카드 결제는 최소 ${ this.company.card_limit[0].toComma() }원부터 가능합니다.`)
                }
                break
            case 20:
                if (!this.$session.isMemberAlive) {
                    return this.$alert('앱에서만 적립금 사용이 가능합니다.')
                }
                if (this.totalPrice > 1000000) {
                    return this.$alert('최대 구매 금액을 100만원을 넘을 수 없습니다.')
                }
                if (this.totalPrice > this.$session.currentMember.point) {
                    return this.$alert('포인트 잔액이 부족합니다.')
                }
                break
            default:
                return this.$alert('상품권을 결제할 결제수단을 선택해주세요.')
        }

        if (!this.agree) {
            return this.$alert('이용약관에 동의해주세요.')
        }

        try {
            await this.$resource.requestPayment({
                bp:           this.phoneNumber,
                rp:           this.phoneNumber,
                online:       1,
                step:         this.step,
                safe:         1,
                order:        _.filter(this.qty, (v) => v.qos > 0),
                prepaid:      this.prepaid ? 1 : 0,
                price:        this.totalPrice,
                request_type: this.$session.isMemberAlive ? 1 : 0
            })
            this.hideOnlinePurchase()
            // 세션정보를 다시 가져온다
            await this.parentRef.setAccessToken()
            return this.$alert(`
                ${ this.phoneNumber } 번호로 결제 링크 요청을 보냈습니다. 확인해보시고 결제를 진행해주세요.<br/><br/><strong class='text-danger line-height-normal fs-2 font-weight-bold'>(* 어떠한 경우에도 결제링크를 타인에게 전송하지 마세요. 금전적인 피해가 발생됩니다.)</strong>
            `)
        } catch (e) {
            return this.$alert(e.message)
        }
    }

    /**
     * 창을 닫는다
     * Comment by Gosomi
     * @date: 2021-09-12
     */
    hideOnlinePurchase() {
        $('body').removeAttr('style')
        location.hash = ''
    }

    render() {
        return this.isShow && <div className="full-window bg-light-dark window-middle text-center online-purchase">
            <div className="online-purchase-window">
                <div className="header text-center">
                    <a className="close-btn" href="" onClick={ (e) => {
                        e.preventDefault()
                        this.hideOnlinePurchase()
                        this.$present(this.topRef) ? this.topRef.setScroll(this.scrollY) : undefined
                        return this.$history.push({
                            pathname: this.backUrl,
                        })
                    } }>
                        <i className="mdi mdi-close fs-5 text-dark"/>
                    </a>
                    <div className={ `brand-card ${ this.company.namespace }` }>
                        <img src={ this.getImagePath(`logo/${ this.company.namespace }.png`) }/>
                    </div>
                    <div className="brand-card-shadow-1">
                    </div>
                    <div className="brand-card-shadow-2">
                    </div>
                </div>
                <div className="body">
                    <div className="content bg-white">
                        <h4>{ this.company.name } 상품권</h4>
                        <p className="text-dark mt-3 fs-3">옵션선택</p>
                        {
                            _.map(this.company.types, (type, index) => {
                                return <div className={ `row m-0 p-0 align-items-center ${ index > 0 ? 'mt-3' : '' }` }
                                            key={ type.gift_type_id }>
                                    <div className="col-auto w-150px m-0 p-0">
                                        <span
                                            className="fs-4 text-dark font-weight-bold">{ type.price.toComma() }</span>
                                        <span className="fs-3 text-dark">원</span>
                                    </div>
                                    <div className="col m-0 p-0 text-right">
                                        <div className="control-qty">
                                            <a className="control-btn" href="" onClick={ (e) => {
                                                e.preventDefault()
                                                this.adjustQty(type.gift_type_id, -1)
                                            } }>
                                                <i className="mdi mdi-minus"/>
                                            </a>
                                            <span className="count">
                            					{ this.qtyByType(type.gift_type_id) }
                            				</span>
                                            <a className="control-btn" href="" onClick={ (e) => {
                                                e.preventDefault()
                                                this.adjustQty(type.gift_type_id, 1)
                                            } }>
                                                <i className="mdi mdi-plus"/>
                                            </a>
                                        </div>
                                        <a className="plus-10-btn ml-2" href="" onClick={ (e) => {
                                            e.preventDefault()
                                            this.adjustQty(type.gift_type_id, 10)
                                        } }>
                                            +10
                                        </a>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className="split-bar">
                    </div>
                    <div className="content bg-white">
                        <h5>결제금액</h5>
                        <div className="mt-3">
                            <div className="row p-0 m-0 align-items-center">
                                <div className="col-auto w-150px p-0 m-0">상품권 금액</div>
                                <div className="col text-right ml-0 p-0">
                                    <div
                                        className="fs-4 text-dark font-weight-bold">{ this.totalCertificatePrice.toComma() }원
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-1">
                            {
                                this.$present(this.currentPurchaseData) && this.currentPurchaseData.pp === 1 && this.prepaid &&
                                <div className="row p-0 m-0 align-items-center">
                                    <div className="col-auto p-0 m-0">
                                        서비스 이용료
                                        <span
                                            className="text-danger fs-2 ml-2">{ this.currentServicePaymentRatio }%부가세포함</span>
                                    </div>
                                    <div className="col text-right ml-0 p-0">
                                        <div
                                            className="fs-4 text-dark font-weight-bold">{ this.currentServicePayment.toComma(0) }원
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="mt-3 pt-3 sum">
                            <div className="row align-items-center">
                                <div className="col-auto">합계</div>
                                <div className="col text-right">
                                    <div
                                        className="fs-4 font-weight-bold text-danger">{ this.totalPrice.toComma() }원
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="split-bar">
                    </div>
                    <div className="content bg-white">
                        <h5>결제수단</h5>
                        {
                            (!this.$session.isMemberAlive || this.$session.currentMember.point_active === 0) &&
                            <div className="mt-3">
                                <div className="row m-0 p-0">
                                    <div className="col-6 m-0 p-0">
                                        <div className="pr-3">
                                            <a className={ `payment pt-60p text-center ${ this.step === 1 ? 'active' : '' }` }
                                               href="" onClick={ (e) => {
                                                e.preventDefault()
                                                this.step = 1
                                            } }>
                                                <div className="d-inline-block align-middle">
                                                    <img src={ this.getImagePath('icon/ico-pay-phone.png') }/>
                                                    <div className="name mt-1">휴대폰</div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-6 m-0 p-0">
                                        <div className="pl-2 pr-2">
                                            <a className={ `payment pt-60p text-center ${ this.step === 0 ? 'active' : '' }` }
                                               href="" onClick={ (e) => {
                                                e.preventDefault()
                                                this.step = 0
                                            } }>
                                                <div className="d-inline-block align-middle">
                                                    <img src={ this.getImagePath('icon/ico-pay-card.png') }/>
                                                    <div className="name mt-1">신용카드</div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    {/*<div className="col-4 m-0 p-0">*/ }
                                    {/*	<div className="pl-3">*/ }
                                    {/*		<a className="payment text-center" href="" onClick={(e) => {}}>*/ }
                                    {/*			<div className="d-inline-block align-middle">*/ }
                                    {/*				<img src={ this.getImagePath('icon/ico-pay-point.png') }/>*/ }
                                    {/*				<div className="name mt-1">적립금</div>*/ }
                                    {/*			</div>*/ }
                                    {/*		</a>*/ }
                                    {/*	</div>*/ }
                                    {/*</div>*/ }
                                </div>
                            </div>
                        }
                        {
                            this.$session.isMemberAlive && this.$session.currentMember.point_active === 1 &&
                            <div className="mt-3">
                                <div className="row m-0 p-0">
                                    <div className="col-4 m-0 p-0">
                                        <div className="pr-2">
                                            <a className={ `payment text-center ${ this.step === 1 ? 'active' : '' }` }
                                               href="" onClick={ (e) => {
                                                e.preventDefault()
                                                this.step = 1
                                            } }>
                                                <div className="d-inline-block align-middle">
                                                    <img src={ this.getImagePath('icon/ico-pay-phone.png') }/>
                                                    <div className="name mt-1 fs-2">휴대폰</div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-4 m-0 p-0">
                                        <div className="pl-1 pr-1">
                                            <a className={ `payment text-center ${ this.step === 0 ? 'active' : '' }` }
                                               href="" onClick={ (e) => {
                                                e.preventDefault()
                                                this.step = 0
                                            } }>
                                                <div className="d-inline-block align-middle">
                                                    <img src={ this.getImagePath('icon/ico-pay-card.png') }/>
                                                    <div className="name mt-1 fs-2">신용카드</div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-4 m-0 p-0">
                                        <div className="pl-2">
                                            <a className={ `payment text-center ${ this.step === 20 ? 'active' : '' }` }
                                               href="" onClick={ (e) => {
                                                e.preventDefault()
                                                if (this.$session.currentMember.point_active == 0) {
                                                    return this.$alert('포인트 사용 활성화가 되지 않았습니다. 앱에서 3명 이상 신규 회원 초대 후 활성화 후 사용해주세요.')
                                                }
                                                this.step = 20
                                            } }>
                                                <div className="d-inline-block align-middle">
                                                    <img src={ this.getImagePath('icon/ico-pay-point.png') }/>
                                                    <div className="name mt-1 fs-2">적립금</div>
                                                    <div
                                                        className="fs-2 text-danger font-weight-bold mt-1">{ this.$session.currentMember.point.toComma() }원
                                                        보유
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <p className="comment text-danger fs-2 mt-2 mb-0">
                            { this.isOkGoodpinServiceTag ?
                                (this.step === 1 ? 'SKT·KT·LG·알뜰폰 100만원 한도' : <span>&nbsp;</span>) :
                                (this.step === 1 ? 'SKT·KT·LG 100만원 한도' : <span>&nbsp;</span>)
                            }
                        </p>
                        { !this.isOkGoodpinServiceTag && this.step === 1 ?
                            <div className="alert alert-danger mt-2">
                                <a className="fs-2 d-block" href="https://ok.goodpin.co.kr"
                                   target="_blank">
                                    <i className="mdi mdi-exclamation-thick"></i>
                                    <span className="font-weight-bold ml-1">알뜰폰 결제</span>는 <span
                                    className="text-primary font-weight-bold border-bottom border-primary">OK굿핀</span>에서 가능합니다.&nbsp;&nbsp;
                                </a>
                            </div> : '' }
                        { this.isOkGoodpinServiceTag && this.step === 1 ?
                            <div className="alert alert-danger fs-2 mt-1">
                                <i className="mdi mdi-information-outline"></i>
                                <span className="text-primary font-weight-bold ml-1">OK굿핀</span>에서는 알뜰폰 결제가 가능합니다!
                            </div> : '' }
                        {
                            this.$present(this.currentPurchaseData) && this.currentPurchaseData.pp === 1 &&
                            <div className="sum mt-3 pt-3">
                                <a href="" className="d-block" onClick={ (e) => {
                                    e.preventDefault()
                                    this.prepaid = true
                                } }>
                                    <div className="row p-0 m-0 align-items-center">
                                        <div className="col-auto p-0 m-0">
                                            <div className={ `round-btn ${ this.prepaid ? 'active' : '' }` }>
                                            </div>
                                        </div>
                                        <div
                                            className={ `col pl-2 pr-0 m-0 ${ this.prepaid ? 'font-weight-bold' : '' }` }>
                                            서비스 이용료를 포함하여 결제합니다.
                                        </div>
                                    </div>
                                </a>
                                <a href="" className="d-block mt-3" onClick={ (e) => {
                                    e.preventDefault()
                                    this.prepaid = false
                                } }>
                                    <div className="row p-0 m-0 align-items-center">
                                        <div className="col-auto p-0 m-0">
                                            <div className={ `round-btn ${ !this.prepaid ? 'active' : '' }` }>
                                            </div>
                                        </div>
                                        <div
                                            className={ `col pl-2 pr-0 m-0 ${ !this.prepaid ? 'font-weight-bold' : '' }` }>
                                            서비스 이용료를 별도로 결제합니다.
                                        </div>
                                    </div>
                                </a>
                            </div>
                        }
                    </div>
                    <div className="split-bar">
                    </div>
                    <div className="content bg-white">
                        <h5>휴대폰 번호</h5>
                        {
                            <input type="tel" className="form-control" value={ this.phoneNumber } onChange={ (e) => {
                                this.phoneNumber = e.currentTarget.value
                            } } disabled={ this.$session.isMemberAlive }/>
                        }
                    </div>
                    <div className="split-bar">
                    </div>
                    <div className="content bg-white">
                        <div className="row p-0 m-0 align-self-center align-items-center">
                            <div className="col-auto w-200px p-0 m-0">
                                <a className="d-block w-100" href="" onClick={ (e) => {
                                    e.preventDefault()
                                    this.agree = !this.agree
                                } }>
                                    <div className={ `checkbox ${ this.agree && 'checked' }` }>
                                        {
                                            this.agree && <i className="mdi mdi-check fs-3"/>
                                        }
                                    </div>
                                    <span className="ml-2 agreement">약관에 동의합니다</span>
                                </a>
                            </div>
                            <div className="col p-0 m-0 text-right">
                                <a className="text-danger border-bottom border-danger" href="" onClick={ (e) => {
                                    e.preventDefault()
                                    return this.$showPolicy()
                                } }>
                                    약관보기
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="" className="bg-danger lh-60px fs-3 d-block text-center text-white font-weight-bold"
                       onClick={ (e) => {
                           e.preventDefault()
                           return this.certificatePayment()
                       } }>
                        결제하기
                    </a>
                </div>
            </div>
        </div>
    }
}