import { ReactResource } from 'support-js/react/resources'

export class BrandResource extends ReactResource {
    constructor() {
        super()
    }

    /**
     * 검색에 해당되는 브랜드 정보를 가져온다
     * Comment by Gosomi
     * @date: 2021-09-07
     */
    async brands(tag: string, categories: Array<string>, companies: Array<string>, order: string, page: number) {
        return this.semaphoreExecute('brands', async () => {
            return this.$http.get('/brand', {
                params: {
                    tag:        tag,
                    categories: categories,
                    companies:  companies,
                    order:      order,
                    page:       page
                }
            })
        })
    }

    /**
     * 검색에 필요한 기본 정보를 로드한다
     * Comment by Gosomi
     * @date: 2021-09-09
     */
    async basicBrand() {
        return this.semaphoreExecute('basicBrand', async () => {
            return this.$http.get('/brand/basic')
        })
    }

    /**
     * APP Session Logout
     * Comment by Gosomi
     * @date: 2021-09-29
     */
    async appLogout() {
        return this.semaphoreExecute('appLogout', async () => {
            return this.$http.delete(`/session/DESTROY`)
        })
    }

    /**
     * 개인 정보 동의 철회 기능 추가
     * Comment by Gosomi
     * @date: 2022-11-17
     */
    async drawPrivatePolicy() {
        return this.semaphoreExecute('drawPrivatePolicy', async () => {
            return this.$http.delete(`/draw/POLICY`)
        })
    }
}