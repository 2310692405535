import React, { createRef, RefObject } from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import $ from 'jquery'

@observer
export class Policy extends React.Component<any, any> {
    @observable isShow: boolean
    template: string

    constructor(props) {
        super(props)
        this.isShow = false
        this.template = require('policy')
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.isShow) {
            $('body').css({
                overflow: 'hidden'
            })
        } else {
            $('body').removeAttr('style')
        }
    }

    showPolicy() {
        this.isShow = true
    }

    render() {
        return this.isShow && <div className="full-window z-10000 bg-light-dark policy-window">
			<div className="policy-container">
				<div className="header">
					<h5>이용약관</h5>
					<a className="close-btn" href="" onClick={ (e) => {
                        e.preventDefault()
                        this.isShow = false
                    } }>
						<i className="mdi mdi-close fs-4"/>
					</a>
				</div>
				<div className="body" dangerouslySetInnerHTML={ { __html: this.template } }>
				</div>
			</div>
		</div>
    }
}

export const PolicyRef: RefObject<Policy> = createRef()