import React from 'react';

export default function() {
  return (
    <div className="terms-index goodpin-container">
      <div className="goodpin-page-title">
        <div className="title-container">
          <div className="font-weight-bold">이용약관</div>
        </div>
      </div>
      <section className="body mx-1280" dangerouslySetInnerHTML={{ __html: this.policyTemplate }}></section>
    </div>
  );
}
